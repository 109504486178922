import React from "react";
import Barcode from "react-barcode";

import useQuery from "utils/useQuery";

const Barra = () => {
  const query = useQuery();
  return (
    <div>
      <Barcode value={query.get("codigo")} />
    </div>
  );
};

export default Barra;
