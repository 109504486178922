import { useState, useEffect } from "react";
import { FilterMatchMode, PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Sidebar } from "primereact/sidebar";
import InputMask from "react-input-mask";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";
import TrataRetornoApi from "utils/TrataRetornoApi";

const Implantacao = () => {
  const tratarRetornoApi = new TrataRetornoApi();
  const requisicao = new Api();
  const { setLoading } = useLoading();
  const exibirNotificacao = useNotification();
  const auth = useAuth();
  const [lista, setLista] = useState([]);
  const [modalHistorico, setModalHistorico] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [modalEdicao, setModalEdicao] = useState(false);
  const [tipoStatusImplantacao, setTipoStatusImplantacao] = useState([]);
  const [formEditarObservacao, setformEditarObservacao] = useState("");
  const [
    formEditarDataBalcaoDisponibilizado,
    setFormEditarDataBalcaoDisponibilizado
  ] = useState("");
  const [
    formEditarDataBalcaoInicioOperacao,
    setFormEditarDataBalcaoInicioOperacao
  ] = useState("");
  const [
    formEditarDataBalcaoPdvOperante,
    setFormEditarDataBalcaoPdvOperante
  ] = useState("");
  const [formEditarStatusImplantacao, setFormEditarStatusImplantacao] =
    useState();
  const [formEditarId, setFormEditarId] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filtros, setFilters] = useState(null);

  const inicializarFiltrosTabela = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    setGlobalFilterValue("");
  };

  const inicializarDados = async () => {
    setLoading(true);

    try {
      const res1 = await requisicao.Get({
        endpoint: "/ImplantacaoBalcaoLoja",
        config: auth.GetHeaders()
      });

      const res2 = await requisicao.Get({
        endpoint: "/TipoAndStatus/ObterStatusImplantacao",
        config: auth.GetHeaders()
      });

      if (res1?.codigoEstadoHttp === 200) {
        setLista(res1?.objetoResposta);
      }

      if (res2?.codigoEstadoHttp === 200) {
        const statuses = res2.objetoResposta.map((item) => ({
          name: item.descricao,
          code: item.id
        }));

        setTipoStatusImplantacao(statuses);
      }
    } catch (error) {
      exibirNotificacao({
        type: "erro",
        message: tratarRetornoApi.obterMensagemErro(error)
      });
    } finally {
      setLoading(false);
    }

    inicializarFiltrosTabela();
  };

  const obterHistorico = async (id) => {
    try {
      const resposta = await requisicao.Get({
        endpoint: `/ImplantacaoBalcaoLoja/ObterHistoricoPorImplantacaoBalcaoLojaId/${id}`,
        config: auth.GetHeaders()
      });

      if (resposta?.codigoEstadoHttp === 200) {
        setHistorico(resposta?.objetoResposta);
      }
    } catch (error) {
      exibirNotificacao({
        type: "erro",
        message: tratarRetornoApi.obterMensagemErro(error)
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      inicializarDados();
    }
  }, [auth]);

  const abrirModalEdicao = (implantacao) => {
    try {
      setFormEditarDataBalcaoDisponibilizado(
        implantacao.dataBalcaoDisponibilizado
          ? MaskUtil.applyDataMask(implantacao.dataBalcaoDisponibilizado)
          : ""
      );
      setFormEditarDataBalcaoInicioOperacao(
        implantacao.dataBalcaoInicioOperacao
          ? MaskUtil.applyDataMask(implantacao.dataBalcaoInicioOperacao)
          : ""
      );
      setFormEditarDataBalcaoPdvOperante(
        implantacao.dataBalcaoPdvOperante
          ? MaskUtil.applyDataMask(implantacao.dataBalcaoPdvOperante)
          : ""
      );

      setFormEditarStatusImplantacao(implantacao.statusImplantacaoId);
      setformEditarObservacao(implantacao.observacoes);
      setFormEditarId(implantacao.id);
      setModalEdicao(true);
    } catch (error) {
      console.error(error);
    }
  };

  const abrirModalHistorico = (id) => {
    try {
      setLoading(true);
      setHistorico([]);
      obterHistorico(id);
      setModalHistorico(true);
    } catch (error) {
      console.error(error);
    }
  };

  const salvarEdicao = async () => {
    setLoading(true);

    const comando = {
      id: formEditarId,
      statusImplantacaoId:
        formEditarStatusImplantacao?.code ?? formEditarStatusImplantacao,
      observacoes: formEditarObservacao,
      dataBalcaoDisponibilizado: formEditarDataBalcaoDisponibilizado
        ? MaskUtil.convertDateToIso(formEditarDataBalcaoDisponibilizado)
        : null,
      dataBalcaoInicioOperacao: formEditarDataBalcaoInicioOperacao
        ? MaskUtil.convertDateToIso(formEditarDataBalcaoInicioOperacao)
        : null,
      dataBalcaoPdvOperante: formEditarDataBalcaoPdvOperante
        ? MaskUtil.convertDateToIso(formEditarDataBalcaoPdvOperante)
        : null,
    };

    try {
      const resposta = await requisicao.Put({
        endpoint: `/ImplantacaoBalcaoLoja`,
        data: comando,
        config: auth.GetHeaders()
      });

      if (resposta?.codigoEstadoHttp === 200) {
        setModalEdicao(false);
        inicializarDados();
      }
    } catch (error) {
      exibirNotificacao({
        type: "erro",
        message: tratarRetornoApi.obterMensagemErro(error)
      });
    } finally {
      setLoading(false);
    }
  };

  const onGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      const thisFilters = { ...filtros };

      thisFilters.global.value = value;

      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  const ClearFilter = () => {
    inicializarFiltrosTabela();
  };

  return (
    <div className="card p-3">
      <div className="d-flex justify-content-between mb-3">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Limpar filtro"
          outlined
          onClick={ClearFilter}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Pesquisa Global"
          />
        </span>
      </div>
      <DataTable
        value={lista}
        stripedRows
        paginator
        rows={20}
        tableStyle={{ minWidth: "50rem" }}
        emptyMessage="Nenhum item encontrado."
        filters={filtros}
      >
        <Column field="codigoLoja" header="Loja" sortable />
        <Column field="balcaoNomeFantasia" header="Balcão" sortable />
        <Column field="statusImplantacaoDescricao" header="Status" />
        <Column
          field="dataStatusImplantacao"
          header="Dt Status"
          sortable
          body={(rowData) =>
            MaskUtil.applyDataMask(rowData.dataStatusImplantacao)
          }
        />
        <Column field="observacoes" header="Observações" />
        <Column
          field="dataBalcaoDisponibilizado"
          header="Dt Balcão Disponível"
          sortable
          body={(rowData) =>
            MaskUtil.applyDataMask(rowData.dataBalcaoDisponibilizado)
          }
        />
        <Column
          field="dataBalcaoInicioOperacao"
          header="Dt Início Operação"
          sortable
          body={(rowData) =>
            MaskUtil.applyDataMask(rowData.dataBalcaoInicioOperacao)
          }
        />
        <Column
          field="dataBalcaoPdvOperante"
          header="Dt PDV Operante"
          sortable
          body={(rowData) =>
            MaskUtil.applyDataMask(rowData.dataBalcaoPdvOperante)
          }
        />
        <Column
          header=""
          body={(rowData) => (
            <div className="d-flex">
              <Button
                className="btn btn-tabela"
                type="button"
                icon="bi bi-pencil"
                onClick={() => abrirModalEdicao(rowData)}
                tooltipOptions={{ position: "top" }}
              />
              <Button
                className="btn btn-tabela"
                type="button"
                icon={PrimeIcons.CLOCK}
                onClick={() => abrirModalHistorico(rowData.id)}
                tooltipOptions={{ position: "top" }}
              />
            </div>
          )}
          style={{ textAlign: "center" }}
        />
      </DataTable>
      <Sidebar
        visible={modalEdicao}
        position="right"
        onHide={() => setModalEdicao(false)}
      >
        <h2>Edição</h2>
        <div>
          <br />
          <b>Status atual:</b>{" "}
          {
            tipoStatusImplantacao?.find(
              (status) => status.code === formEditarStatusImplantacao
            )?.name
          }
        </div>
        <div className="mt-3 d-flex flex-column gap-3">
          <div>
            <Dropdown
              value={formEditarStatusImplantacao}
              onChange={(e) => setFormEditarStatusImplantacao(e.value)}
              options={tipoStatusImplantacao}
              optionLabel="name"
              placeholder="Selecione o novo Status"
              className="w-100"
            />
          </div>
          <div>
            <label>Observações</label>
            <InputTextarea
              value={formEditarObservacao}
              onChange={(e) => setformEditarObservacao(e.target.value)}
              rows={5}
              className="w-100"
            />
          </div>
          <div>
            <label>Dt Balcão Disponibilizado (dd/mm/yyyy)</label>
            <InputMask
              mask="99/99/9999"
              value={formEditarDataBalcaoDisponibilizado}
              onChange={(e) =>
                setFormEditarDataBalcaoDisponibilizado(e.target.value)
              }
              className="w-100 p-inputtext p-component"
              placeholder="dd/mm/yyyy"
            />
          </div>
          <div>
            <label>Dt Início Operação (dd/mm/yyyy)</label>
            <InputMask
              mask="99/99/9999"
              value={formEditarDataBalcaoInicioOperacao}
              onChange={(e) =>
                setFormEditarDataBalcaoInicioOperacao(e.target.value)
              }
              className="w-100 p-inputtext p-component"
              placeholder="dd/mm/yyyy"
            />
          </div>
          <div>
            <label>Dt PDV Operante (dd/mm/yyyy)</label>
            <InputMask
              mask="99/99/9999"
              value={formEditarDataBalcaoPdvOperante}
              onChange={(e) =>
                setFormEditarDataBalcaoPdvOperante(e.target.value)
              }
              className="w-100 p-inputtext p-component"
              placeholder="dd/mm/yyyy"
            />
          </div>
          <Button
            className="btn"
            type="button"
            label="Salvar"
            onClick={() => salvarEdicao()}
          />
        </div>
      </Sidebar>
      <Dialog
        header="Histórico"
        visible={modalHistorico}
        style={{ width: "75vw" }}
        maximizable
        modal
        contentStyle={{ height: "300px" }}
        onHide={() => setModalHistorico(false)}
      >
        <DataTable
          value={historico}
          stripedRows
          scrollable
          scrollHeight="flex"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            field="statusImplantacaoAtualDescricao"
            header="Status Atual"
          />
          <Column
            field="statusImplantacaoAnteriorDescricao"
            header="Status Anterior"
          />
          <Column field="observacoes" header="Observações" />
          <Column
            field="dataCriacao"
            header="Dt"
            body={(rowData) =>
              MaskUtil.applyDataAndHoraMask(rowData.dataCriacao)
            }
          />
        </DataTable>
      </Dialog>
    </div>
  );
};

export default Implantacao;
