import { useState } from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

const EnderecoModule = ({ ListaEnderecos, setListaEnderecos }) => {
  const { setLoading } = useLoading();
  const Requicicao = new Api();
  const notify = useNotification();

  const [Endereco, setEndereco] = useState({
    apelido: "",
    cep: "",
    enderecoDaPessoa: "",
    numero: "",
    complemento: "",
    estado: "",
    cidade: "",
    bairro: ""
  });

  const SetDadosEndereco = async (cep) => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        url: `https://viacep.com.br/ws/${cep}/json/`
      });
      if (data.uf) {
        setEndereco((este) => ({
          ...este,
          ...{
            estado: data.uf,
            cidade: data.localidade,
            enderecoDaPessoa: data.logradouro,
            bairro: data.bairro
          }
        }));
      } else {
        notify({
          type: "error",
          message: "Cep não exite"
        });
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Cep não exite"
      });
    } finally {
      setLoading(false);
    }
  };

  const HandleChangeEndereco = (event) => {
    const { name, value } = event.target;
    if (name === "cep") {
      const maskedValue = MaskUtil.applyCepMask(value);
      setEndereco({ ...Endereco, [name]: maskedValue });

      const noMaskedValue = value.replace(/\D/g, "").substring(0, 8);
      if (noMaskedValue.length === 8) {
        SetDadosEndereco(maskedValue);
      }
    } else {
      setEndereco({ ...Endereco, [name]: value });
    }
  };

  const AddEndereco = () => {
    try {
      const newLista = [];
      ListaEnderecos.forEach((e) => {
        newLista.push(e);
      });
      newLista.push({ ...Endereco, indice: newLista.length });
      setListaEnderecos(newLista);
      setEndereco({
        apelido: "",
        cep: "",
        enderecoDaPessoa: "",
        numero: "",
        complemento: "",
        estado: "",
        cidade: "",
        bairro: ""
      });
    } catch (error) {
      console.error(error);
    }
  };

  const RemoverEndereco = (rowData) => {
    try {
      const newLista = [];
      ListaEnderecos.forEach((e, i) => {
        if (e.indice !== rowData.indice) newLista.push({ ...e, indice: i });
      });
      setListaEnderecos(newLista);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="col-md-6 mb-3">
        <label htmlFor="apelido" className="form-label">
          Apelido do Endereço
        </label>
        <InputText
          id="apelido"
          name="apelido"
          value={Endereco.apelido}
          onChange={HandleChangeEndereco}
          className="form-control"
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="cep" className="form-label">
          CEP
        </label>
        <InputText
          id="cep"
          name="cep"
          value={Endereco.cep}
          onChange={HandleChangeEndereco}
          className="form-control"
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="enderecoDaPessoa" className="form-label">
          Endereço
        </label>
        <InputText
          id="enderecoDaPessoa"
          name="enderecoDaPessoa"
          value={Endereco.enderecoDaPessoa}
          onChange={HandleChangeEndereco}
          className="form-control"
          disabled
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="numero" className="form-label">
          Número
        </label>
        <InputText
          id="numero"
          name="numero"
          value={Endereco.numero}
          onChange={HandleChangeEndereco}
          className="form-control"
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="estado" className="form-label">
          Estado
        </label>
        <InputText
          id="estado"
          name="estado"
          value={Endereco.estado}
          onChange={HandleChangeEndereco}
          className="form-control"
          disabled
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="complemento" className="form-label">
          Complemento
        </label>
        <InputText
          id="complemento"
          name="complemento"
          value={Endereco.complemento}
          onChange={HandleChangeEndereco}
          className="form-control"
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="cidade" className="form-label">
          Cidade
        </label>
        <InputText
          id="cidade"
          name="cidade"
          value={Endereco.cidade}
          onChange={HandleChangeEndereco}
          className="form-control"
          disabled
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="bairro" className="form-label">
          Bairro
        </label>
        <InputText
          id="bairro"
          name="bairro"
          value={Endereco.bairro}
          onChange={HandleChangeEndereco}
          className="form-control"
          disabled
        />
      </div>

      <div className="col-md-6 mb-3">
        <div className="h-100 d-flex align-items-end">
          <Button
            type="button"
            label="Adicionar Endereço"
            className="btn btn-primary"
            onClick={AddEndereco}
          />
        </div>
      </div>

      <div className="col-12">
        <DataTable value={ListaEnderecos} emptyMessage="Nenhum endereço">
          <Column field="apelido" header="Apelido" />
          <Column field="cep" header="Cep" />
          <Column field="enderecoDaPessoa" header="Endereço" />
          <Column field="numero" header="Número" />
          <Column field="complemento" header="Complemento" />
          <Column field="estado" header="Estado" />
          <Column field="cidade" header="Cidade" />
          <Column field="bairro" header="Bairro" />
          <Column
            header="Ações"
            body={(rowData) => (
              <Button
                className="btn btn-tabela"
                type="button"
                icon="bi bi-trash"
                onClick={() => RemoverEndereco(rowData)}
              />
            )}
          />
        </DataTable>
      </div>
    </>
  );
};

EnderecoModule.propTypes = {
  ListaEnderecos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      apelido: PropTypes.string,
      cep: PropTypes.string,
      enderecoDaPessoa: PropTypes.string,
      numero: PropTypes.string,
      complemento: PropTypes.string,
      estado: PropTypes.string,
      cidade: PropTypes.string
    })
  ).isRequired,
  setListaEnderecos: PropTypes.func.isRequired
};

export default EnderecoModule;
