import FormVendedor from "components/FormVendedor";

import useQuery from "utils/useQuery";

const EditarVendedor = () => {
  const query = useQuery();
  return (
    <div className="card">
      <FormVendedor VendedorId={query.get("id")} />
    </div>
  );
};

export default EditarVendedor;
