/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect } from "react";

import { format } from "date-fns";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Sidebar } from "primereact/sidebar";

import ExportToExcel from "components/ExportToExcel";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

const ListaUsuario = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [Lista, setLista] = useState([]);

  const [ShowModal, setShowModal] = useState(false);

  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: "/ClientePdv",
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        setLista(resposta.objetoResposta);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const [dados, setDados] = useState({
    id: "",
    cpfOuCnpj: "",
    nome: "",
    plano: "",
    observacoes: ""
  });

  const LimparDataUser = () => {
    setDados({
      id: "",
      cpfOuCnpj: "",
      nome: "",
      plano: "",
      observacoes: ""
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Aplica a máscara apenas para o campo CPF ou CNPJ
    if (name === "cpfOuCnpj") {
      const maskedValue = MaskUtil.applyCpfCnpjMask(value);
      setDados({ ...dados, [name]: maskedValue });
    } else {
      setDados({ ...dados, [name]: value });
    }
  };

  const UpdataUser = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Put({
        endpoint: "/ClientePdv",
        data: dados,
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        notify({
          type: "success",
          message: "Cliente atualizado."
        });
        setShowModal(false);
        StateDataPage();
      }
    } catch (error) {
      console.error(error);
      notify({
        type: "error",
        message: "Erro ao atualizar o cliente"
      });
    } finally {
      setLoading(false);
    }
  };

  const AddUser = async () => {
    try {
      setLoading(true);
      const novoObjeto = {};
      for (const chave in dados) {
        if (chave !== "id") {
          novoObjeto[chave] = dados[chave];
        }
      }
      await Requicicao.Post({
        endpoint: "/ClientePdv",
        data: novoObjeto,
        config: auth.GetHeaders()
      });
      notify({
        type: "success",
        message: "Cliente Cadastrado."
      });
      setShowModal(false);
      StateDataPage();
    } catch (error) {
      console.error(error);
      notify({
        type: "error",
        message: "Erro ao cadastrar o cliente"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (dados.id !== "") UpdataUser();
    else AddUser();
  };

  const handleEditClick = (rowData) => {
    const data = {
      id: rowData.id,
      cpfOuCnpj: rowData.cpfOuCnpj,
      nome: rowData.nome,
      plano: rowData.plano,
      observacoes: rowData.observacoes
    };
    setDados(data);
  };

  const onGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      const thisFilters = { ...filters };

      thisFilters.global.value = value;

      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nome: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      plano: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const Desativars = async (rowData) => {
    try {
      setLoading(true);
      await Requicicao.Delete({
        endpoint: "/ClientePdv",
        params: { id: rowData.id },
        config: auth.GetHeaders()
      });
      notify({
        type: "success",
        message: "Cliente desativado."
      });
      StateDataPage();
    } catch (error) {
      console.error(error);
      notify({
        type: "error",
        message: "Erro ao desativado o cliente."
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage();
    }
    initFilters();
  }, [auth]);

  const Confirm = (event, rowData) => {
    confirmPopup({
      group: "headless",
      target: event.currentTarget,
      message: "Deseja realmente excluir este item?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => {
        Desativars(rowData);
      },
      reject: () => {},
      acceptLabel: "Sim",
      rejectLabel: "Não"
    });
  };

  return (
    <div>
      <div className="w-100 d-flex flex-row gap-3 mb-3">
        <Button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setShowModal(true);
            LimparDataUser();
          }}
        >
          Criar
        </Button>
        {/* <ExportToExcel data={Lista} fileName="Lista Usuario" /> */}
      </div>
      <Sidebar
        visible={ShowModal}
        position="right"
        onHide={() => setShowModal(false)}
      >
        <form onSubmit={handleSubmit} className="card-body">
          <div className="row">
            <div className="col-12">
              <h5 className="mb-4">
                {dados.id !== "" ? "Editar Usuário" : " Usuário"}
              </h5>
            </div>
            <div className="col-12">
              <label htmlFor="nome" className="form-label">
                Nome
              </label>
              <InputText
                id="nome"
                name="nome"
                value={dados.nome}
                onChange={handleChange}
                className="form-control"
              />
            </div>

            <div className="col-12">
              <label htmlFor="cpfOuCnpj" className="form-label">
                CPF ou CNPJ
              </label>
              <InputText
                id="cpfOuCnpj"
                name="cpfOuCnpj"
                value={dados.cpfOuCnpj}
                onChange={handleChange}
                className="form-control"
              />
            </div>

            <div className="col-12">
              <label htmlFor="plano" className="form-label">
                Plano
              </label>
              <InputText
                id="plano"
                name="plano"
                value={dados.plano}
                onChange={handleChange}
                className="form-control"
              />
            </div>

            <div className="col-md-12 mb-3">
              <label htmlFor="observacoes" className="form-label">
                Observações
              </label>
              <InputTextarea
                id="observacoes"
                name="observacoes"
                rows={3}
                value={dados.observacoes}
                onChange={handleChange}
                className="form-control"
              />
            </div>

            <div className="col-md-12 mb-3">
              <div className="w-100 d-flex flex-row-reverse">
                <Button
                  type="submit"
                  label={dados.id !== "" ? "Atualizar" : "Salvar"}
                  className="btn btn-primary"
                />
              </div>
            </div>
          </div>
        </form>
      </Sidebar>
      <div className="card p-3">
        <div className="d-flex justify-content-between mb-3">
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Limpar filtro"
            outlined
            onClick={clearFilter}
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Pesquisa Global"
            />
          </span>
        </div>
        <DataTable
          value={Lista}
          stripedRows
          paginator
          rows={20}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhum usuário encontrado."
          globalFilterFields={["nome", "plano"]}
          filters={filters}
        >
          <Column field="id" header="Código" sortable />
          <Column field="nome" header="Nome" sortable />
          <Column field="plano" header="Plano" sortable />
          <Column
            field="dataCriacao"
            header="Data Criação"
            sortable
            body={(rowData) =>
              format(new Date(rowData.dataCriacao), "dd/MM/yyyy HH:mm:ss")
            }
          />
          <Column
            header="Ações"
            body={(rowData) => (
              <div>
                <ConfirmPopup />
                <div className="d-flex flex-row gap-3">
                  <Button
                    className="btn btn-tabela"
                    type="button"
                    icon="bi bi-pencil"
                    onClick={() => {
                      handleEditClick(rowData);
                      setShowModal(true);
                    }}
                  />
                  <Button
                    className="btn btn-tabela"
                    type="button"
                    icon="bi bi-trash"
                    onClick={(event) => {
                      Confirm(event, rowData);
                    }}
                  />
                </div>
              </div>
            )}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default ListaUsuario;
