import PropTypes from "prop-types";

import { InputText } from "primereact/inputtext";

import MaskUtil from "utils/MaskUtil";

const PessoaModule = ({ Pessoa, setPessoa }) => {
  const HandleChangePessoa = (event) => {
    const { name, value } = event.target;

    // Aplica a máscara apenas para o campo CPF ou CNPJ
    if (name === "cpfOuCnpj") {
      const maskedValue = MaskUtil.applyCpfCnpjMask(value);
      setPessoa({ ...Pessoa, [name]: maskedValue });
    } else {
      setPessoa({ ...Pessoa, [name]: value });
    }
  };

  return (
    <>
      <div className="col-md-6 mb-3">
        <label htmlFor="nomeCompletoOuRazaoSocial" className="form-label">
          Nome Completo ou Razão Social
        </label>
        <InputText
          id="nomeCompletoOuRazaoSocial"
          name="nomeCompletoOuRazaoSocial"
          value={Pessoa.nomeCompletoOuRazaoSocial}
          onChange={HandleChangePessoa}
          className="form-control"
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="apelidoOuNomeFantasia" className="form-label">
          Apelido ou Nome Fantasia
        </label>
        <InputText
          id="apelidoOuNomeFantasia"
          name="apelidoOuNomeFantasia"
          value={Pessoa.apelidoOuNomeFantasia}
          onChange={HandleChangePessoa}
          className="form-control"
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="pessoa.cpfOuCnpj" className="form-label">
          CPF ou CNPJ
        </label>
        <InputText
          id="pessoa.cpfOuCnpj"
          name="cpfOuCnpj"
          value={Pessoa.cpfOuCnpj}
          onChange={HandleChangePessoa}
          className="form-control"
        />
      </div>
    </>
  );
};

PessoaModule.propTypes = {
  Pessoa: PropTypes.shape({
    nomeCompletoOuRazaoSocial: PropTypes.string,
    apelidoOuNomeFantasia: PropTypes.string,
    cpfOuCnpj: PropTypes.string
  }).isRequired,
  setPessoa: PropTypes.func.isRequired
};

export default PessoaModule;
