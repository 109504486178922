import React from "react";

import FormOperador from "components/FormOperador";

const CriarOperador = () => (
  <div className="card">
    <FormOperador />
  </div>
);

export default CriarOperador;
