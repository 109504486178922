/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */

import { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

// import { Avatar } from "primereact/avatar";
// import { Badge } from "primereact/badge";
import { OverlayPanel } from "primereact/overlaypanel";

import { useAuth } from "context/AuthContext";

import IconUser from "../../assets/icons/l-1.jpg";
import Logo from "../../assets/img/logo/black-full.63543a82.png";
import "./styled.css";

const Header = () => {
  const auth = useAuth();
  const op = useRef(null);
  // const notification = useRef(null);
  const navigate = useNavigate();

  // const notificationMenuItems = [
  //   { label: "Notificação 1" },
  //   { label: "Notificação 2" },
  //   { label: "Notificação 3" }
  // ];

  const [Nome, setNome] = useState("Nome");

  useEffect(() => {
    if (auth.isAuthenticated()) {
      const nomeCompleto = auth.UserNome().toString();
      const nomeAjustado =
        nomeCompleto.length > 40
          ? `${nomeCompleto.substring(0, 40)}...`
          : nomeCompleto;

      setNome(nomeAjustado);
    }
  }, [auth]);

  return (
    <header className="header-container">
      <div className="header-box">
        <Link to="/app" className="header-item-center">
          <img src={Logo} alt="Logo da Empresa" style={{ height: "40px" }} />
        </Link>
        <div className="header-item-right">
          <div className="header-box-item-menu">
            {/* <button
              type="button"
              className="button-none"
              onClick={(e) => {
                if (notificationMenuItems.length > 0) {
                  notification.current.toggle(e);
                } else {
                  navigate("/app/notifications");
                }
              }}
            >
              <Avatar
                className="p-overlay-badge"
                icon="pi pi-bell"
                shape="circle"
                style={{ backgroundColor: "#0000000", color: "#000000" }}
              >
                <Badge value={notificationMenuItems.length} severity="danger" />
              </Avatar>
            </button>
            <OverlayPanel ref={notification}>
              {notificationMenuItems.map((item, index) => (
                <div key={index} className="notification-item">
                  {item.label}
                </div>
              ))}
            </OverlayPanel> */}
          </div>
          <button
            type="button"
            className="button-none header-button-user"
            onClick={(e) => op.current.toggle(e)}
          >
            <span>{Nome}</span>
            <span>
              <img className="header-button-img" src={IconUser} alt="user" />
            </span>
          </button>
          <OverlayPanel ref={op}>
            <div className="d-flex flex-column gap-3">
              {/* <Link className="btn btn-user" to="/app/seusdados">
                Seus dados
              </Link> */}
              <button
                className="btn btn-user"
                type="button"
                onClick={() => {
                  auth.logout();
                  navigate("/");
                }}
              >
                Sair
              </button>
            </div>
          </OverlayPanel>
        </div>
      </div>
    </header>
  );
};

export default Header;
