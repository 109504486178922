import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

import EmailModule from "../FormEmpresa/modules/EmailModule";
import EnderecoModule from "../FormEmpresa/modules/EnderecoModule";
import PessoaModule from "../FormEmpresa/modules/PessoaModule";
import TelefoneModule from "../FormEmpresa/modules/TelefoneModule";

const FormPontoVenda = ({ PontoVendaId }) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();
  const navigate = useNavigate();

  const [dados, setDados] = useState({
    codigoLoja: "",
    pessoa: {
      nomeCompletoOuRazaoSocial: "",
      apelidoOuNomeFantasia: "",
      cpfOuCnpj: "",
      inscricaoEstadual: "",
      inscricaoMunicipal: "",
      enderecos: [],
      telefones: [],
      emails: []
    }
  });

  const [Pessoa, setPessoa] = useState({
    nomeCompletoOuRazaoSocial: "",
    apelidoOuNomeFantasia: "",
    cpfOuCnpj: ""
  });

  const [ListaEnderecos, setListaEnderecos] = useState([]);
  const [LitasTelefone, setLitasTelefone] = useState([]);
  const [ListaEmail, setListaEmail] = useState([]);

  const AddLoja = async () => {
    try {
      await Requicicao.Post({
        endpoint: "/PontoVendaAnfitriao",
        data: dados,
        config: auth.GetHeaders()
      });
      notify({
        type: "sucesso",
        message: "Criado com sucesso"
      });
      navigate("/app/pontoVenda");
    } catch (error) {
      notify({
        type: "erro",
        message:
          "Erro ao tentar executar esta ação. Por favor, entre em contato com o suporte."
      });
    }
  };

  const UpdateLoja = async () => {
    try {
      await Requicicao.Put({
        endpoint: "/PontoVendaAnfitriao",
        data: dados,
        config: auth.GetHeaders()
      });
      notify({
        type: "sucesso",
        message: "Atualização realizada com sucesso."
      });
    } catch (error) {
      notify({
        type: "erro",
        message:
          "Erro ao tentar executar esta ação. Por favor, entre em contato com o suporte."
      });
    }
  };

  const handleSubmit = async (event) => {
    if (!dados.codigoLoja || dados.codigoLoja.length < 2) {
      notify({
        type: "aviso",
        message:
          "Código da Loja deve ser preenchido com pelo menos 2 caracteres numéricos."
      });

      return;
    }
    event.preventDefault();
    try {
      setLoading(true);
      if (PontoVendaId === "") await AddLoja();
      else await UpdateLoja();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const FitroElementoslista = (lista, tiop) => {
    const newListe = [];

    switch (tiop) {
      case "enderecos":
        lista.forEach((e) => {
          const {
            id,
            apelido,
            cep,
            enderecoDaPessoa,
            numero,
            complemento,
            estado,
            cidade,
            bairro
          } = e;
          newListe.push({
            id,
            apelido,
            cep,
            enderecoDaPessoa,
            numero,
            complemento,
            estado,
            cidade,
            bairro
          });
        });
        return newListe;
      case "telefones":
        lista.forEach((e) => {
          const { id, apelido, ddd, numero } = e;
          newListe.push({
            id,
            apelido,
            ddd,
            numero
          });
        });
        return newListe;
      case "emails":
        lista.forEach((e) => {
          const { id, apelido, emailDaPessoa } = e;
          newListe.push({
            id,
            apelido,
            emailDaPessoa
          });
        });
        return newListe;
      default:
        return newListe;
    }
  };

  const GetPontoVenda = async () => {
    try {
      setLoading(true);
      const { objetoResposta } = await Requicicao.Get({
        endpoint: "/PontoVendaAnfitriao/ObterPorId",
        params: { id: PontoVendaId },
        config: auth.GetHeaders()
      });
      const {
        id,
        nomeCompletoOuRazaoSocial,
        apelidoOuNomeFantasia,
        cpfOuCnpj,
        inscricaoEstadual,
        inscricaoMunicipal
      } = objetoResposta.pessoa;
      setPessoa({
        id,
        nomeCompletoOuRazaoSocial,
        apelidoOuNomeFantasia,
        cpfOuCnpj,
        inscricaoEstadual,
        inscricaoMunicipal
      });

      setDados((esta) => ({
        ...esta,
        ...{
          id: objetoResposta.id,
          ehMatriz: objetoResposta.ehMatriz,
          codigoLoja: objetoResposta.codigoLoja,
          pessoa: {
            ...esta.pessoa,
            ...{
              id,
              nomeCompletoOuRazaoSocial,
              apelidoOuNomeFantasia,
              cpfOuCnpj,
              inscricaoEstadual,
              inscricaoMunicipal,
              enderecos: FitroElementoslista(
                objetoResposta.pessoa.enderecos,
                "enderecos"
              ),
              telefones: FitroElementoslista(
                objetoResposta.pessoa.telefones,
                "telefones"
              ),
              emails: FitroElementoslista(
                objetoResposta.pessoa.emails,
                "emails"
              )
            }
          }
        }
      }));

      if (objetoResposta.matrizId !== null) {
        setDados((esta) => ({ ...esta, matrizId: objetoResposta.matrizId }));
      } else {
        setDados((esta) => ({ ...esta, matrizId: null }));
      }

      const listaEnderecos = [];
      objetoResposta.pessoa.enderecos.forEach((element, i) => {
        listaEnderecos.push({ ...element, indice: i });
      });
      setListaEnderecos(listaEnderecos);
      const listaTelefone = [];
      objetoResposta.pessoa.telefones.forEach((element, i) => {
        listaTelefone.push({ ...element, indice: i });
      });
      setLitasTelefone(listaTelefone);
      const listaEmail = [];
      objetoResposta.pessoa.emails.forEach((element, i) => {
        listaEmail.push({ ...element, indice: i });
      });
      setListaEmail(listaEmail);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const HandleChangeDados = (event) => {
    const { name, value } = event.target;

    if (name.startsWith("pessoa.")) {
      const pessoaField = name.split(".")[1];
      setDados((prevState) => ({
        ...prevState,
        pessoa: {
          ...prevState.pessoa,
          [pessoaField]: value
        }
      }));
    } else {
      setDados((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      if (PontoVendaId !== "") GetPontoVenda();
    }
  }, [auth]);

  useEffect(() => {
    setDados({ ...dados, pessoa: { ...dados.pessoa, ...Pessoa } });
  }, [Pessoa]);

  useEffect(() => {
    if (LitasTelefone.length > 0) {
      const newLista = [];
      LitasTelefone.forEach((e) => {
        const { id, apelido, ddd, numero } = e;
        newLista.push({
          id,
          apelido,
          ddd,
          numero
        });
      });

      setDados((este) => ({
        ...este,
        pessoa: { ...dados.pessoa, telefones: newLista }
      }));
    }
  }, [LitasTelefone]);

  useEffect(() => {
    if (ListaEnderecos.length > 0) {
      const newLista = [];
      ListaEnderecos.forEach((e) => {
        const {
          id,
          apelido,
          cep,
          enderecoDaPessoa,
          numero,
          complemento,
          estado,
          cidade,
          bairro
        } = e;
        newLista.push({
          id,
          apelido,
          cep,
          enderecoDaPessoa,
          numero,
          complemento,
          estado,
          cidade,
          bairro
        });
      });

      setDados((este) => ({
        ...este,
        pessoa: { ...dados.pessoa, enderecos: newLista }
      }));
    }
  }, [ListaEnderecos]);

  useEffect(() => {
    if (ListaEmail.length > 0) {
      const newLista = [];
      ListaEmail.forEach((e) => {
        const { id, apelido, emailDaPessoa } = e;
        newLista.push({ id, apelido, emailDaPessoa });
      });

      setDados((este) => ({
        ...este,
        pessoa: { ...dados.pessoa, emails: newLista }
      }));
    }
  }, [ListaEmail]);

  return (
    <form onSubmit={handleSubmit} className="card-body">
      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="codigoLoja" className="form-label">
            Código da Loja
          </label>
          <InputText
            id="codigoLoja"
            name="codigoLoja"
            value={dados.codigoLoja}
            onChange={HandleChangeDados}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="inscricaoEstadual" className="form-label">
            Inscrição Estadual
          </label>
          <InputText
            id="inscricaoEstadual"
            name="pessoa.inscricaoEstadual"
            value={dados.pessoa.inscricaoEstadual}
            onChange={HandleChangeDados}
            className="form-control"
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="inscricaoMunicipal" className="form-label">
            Inscrição Municipal
          </label>
          <InputText
            id="inscricaoMunicipal"
            name="pessoa.inscricaoMunicipal"
            value={dados.pessoa.inscricaoMunicipal}
            onChange={HandleChangeDados}
            className="form-control"
          />
        </div>

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <PessoaModule Pessoa={Pessoa} setPessoa={setPessoa} />

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <EnderecoModule
          ListaEnderecos={ListaEnderecos}
          setListaEnderecos={setListaEnderecos}
        />

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <TelefoneModule
          LitasTelefone={LitasTelefone}
          setLitasTelefone={setLitasTelefone}
        />

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <EmailModule ListaEmail={ListaEmail} setListaEmail={setListaEmail} />

        <div className="col-md-12 mb-3">
          <div className="w-100 d-flex flex-row-reverse">
            <Button
              type="submit"
              label={PontoVendaId === "" ? "Salvar" : "Atualizar"}
              className="btn btn-primary"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

FormPontoVenda.propTypes = { PontoVendaId: PropTypes.string };

FormPontoVenda.defaultProps = {
  PontoVendaId: ""
};

export default FormPontoVenda;
