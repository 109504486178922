import React, { createContext, useContext, useState, useEffect } from "react";
import Api from "utils/Api";
import { useAuth } from "context/AuthContext";

const SystemVersionContext = createContext();

export const SystemVersionProvider = ({ children }) => {
  const [versaoSistema, setVersaoSistema] = useState("");
  const auth = useAuth();
  const Requicicao = new Api();

  useEffect(() => {
    const getVersaoSistema = async () => {
      try {
        const data = await Requicicao.Get({
          endpoint: "/ConfiguracaoSistema/Obter",
          params: { configuracao: 1 },
          config: auth.GetHeaders(),
        });

        if (data.configuracao === "VersaoSistema") {
          setVersaoSistema(data.valor);
        }
      } catch (error) {
        console.error("Erro ao buscar versão do sistema:", error);
      }
    };

    getVersaoSistema();
  }, [auth]);

  return (
    <SystemVersionContext.Provider value={versaoSistema}>
      {children}
    </SystemVersionContext.Provider>
  );
};

export const useSystemVersion = () => useContext(SystemVersionContext);
