import FormProduto from "components/FormProduto";

import useQuery from "utils/useQuery";

const EditarPruduto = () => {
  const query = useQuery();
  return (
    <div className="card">
      <FormProduto ProdutoId={query.get("id")} />
    </div>
  );
};

export default EditarPruduto;
