class TrataRetornoApi {
  constructor() {
    this.MensagemPadrao = "Erro no processamento da solicitação.";
  }

  obterMensagemErro(retornoComErro) {
    if (retornoComErro?.response?.status === 400) {
      if (retornoComErro?.response?.data?.errors) {
        return Object.values(retornoComErro.response.data.errors).flat().join('<br>');
      }
      if (retornoComErro?.response?.data?.mensagemAdicional) {
        return retornoComErro.response.data.mensagemAdicional;
      }
    }
    return this.MensagemPadrao;
  }
}

export default TrataRetornoApi;
