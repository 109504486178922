import FormOperador from "components/FormOperador";

import useQuery from "utils/useQuery";

const EditarOperador = () => {
  const query = useQuery();

  return (
    <div className="card">
      <FormOperador operadorId={query.get("id")} />
    </div>
  );
};

export default EditarOperador;
