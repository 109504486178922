import { useState, useEffect } from "react";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

const ListaFilial = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();

  const [DialogVisible, setDialogVisible] = useState(false);
  const [Customers, setCustomers] = useState([]);

  const [Lista, setLista] = useState([]);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: "/AberturaFechamentoCaixa/ObterAgrupadoPorFilial",
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        setLista(resposta.objetoResposta);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nome: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      nomeFantasia: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      cnpj: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    });
    setGlobalFilterValue("");
  };

  const ClearFilter = () => {
    InitFilters();
  };

  const OnGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      const thisFilters = { ...filters };

      thisFilters.global.value = value;

      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage();
    }
    InitFilters();
  }, [auth]);

  const dialogFooterTemplate = () => (
    <Button
      label="Ok"
      icon="pi pi-check"
      onClick={() => setDialogVisible(false)}
    />
  );

  return (
    <div className="card p-3">
      <div className="d-flex justify-content-between mb-3">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Limpar filtro"
          outlined
          onClick={ClearFilter}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={OnGlobalFilterChange}
            placeholder="Pesquisa Global"
          />
        </span>
      </div>
      <DataTable
        value={Lista}
        stripedRows
        paginator
        rows={20}
        tableStyle={{ minWidth: "50rem" }}
        emptyMessage="Nenhuma empresa encontrada."
        globalFilterFields={["nomeFantasia", "razaoSocial", "cnpj"]}
        filters={filters}
      >
        <Column field="nomeFantasia" header="Nome" sortable />
        <Column field="razaoSocial" header="Razão Social" sortable />
        <Column field="cnpj" header="CNPJ" sortable />
        <Column
          header="Ações"
          body={(rowData) => (
            <div className="d-flex flex-row gap-3">
              <Button
                className="btn btn-tabela"
                type="button"
                icon="pi pi-search"
                onClick={() => {
                  setDialogVisible(true);
                  setCustomers(rowData.datas);
                }}
              />
            </div>
          )}
        />
      </DataTable>
      <Dialog
        header="Resumo"
        visible={DialogVisible}
        style={{ width: "75vw" }}
        maximizable
        modal
        contentStyle={{ height: "300px" }}
        onHide={() => setDialogVisible(false)}
        footer={dialogFooterTemplate}
      >
        <DataTable
          value={Customers}
          scrollable
          stripedRows
          scrollHeight="flex"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            sortable
            field="aberturaData"
            header="Data Abertura"
            body={(rowData) =>
              MaskUtil.applyDataAndHoraMask(rowData.aberturaData)
            }
          />
          <Column field="aberturaObservacao" header="Observação Abertura" />
          <Column
            field="aberturaResponsavelNome"
            header="Responsavel Abertura"
          />
          <Column
            field="fechamentoData"
            header="Data Fechamento"
            body={(rowData) =>
              MaskUtil.applyDataAndHoraMask(rowData.fechamentoData)
            }
          />
          <Column field="fechamentoObservacao" header="Observação Fechamento" />
          <Column
            field="fechamentoResponsavelNome"
            header="Responsável Fechamento"
          />
        </DataTable>
      </Dialog>
    </div>
  );
};

export default ListaFilial;
