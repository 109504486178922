import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "context/AuthContext";
import { useSystemVersion } from "context/SystemVersionContext"; // Importa o contexto da versão do sistema
import "./styled.css";

const Sidebar = ({ Show, setShow }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const versaoSistema = useSystemVersion(); // Usa o contexto da versão do sistema

  const [NivelUser, setNivelUser] = useState([]);
  const [ListaSubitem, setListaSubitem] = useState([]);
  const [ItemSelect, setItemSelect] = useState("");

  const items = [
    {
      label: "Home",
      icon: "bi bi-house",
      Nivel: [10, 20],
      to: "/app",
    },
    {
      label: "Cadastros",
      icon: "pi pi-file-edit",
      Nivel: [10, 20],
      children: [
        {
          label: "Lojas",
          icon: "pi pi-shopping-cart",
          to: "/app/pontoVenda",
          Nivel: [10],
        },
        {
          label: "Balcões",
          icon: "pi pi-building",
          to: "/app/empresa",
          Nivel: [10],
        },
        {
          label: "Contas de Acesso",
          icon: "pi pi-user",
          to: "/app/conta",
          Nivel: [10, 20],
        },
        {
          label: "Grupos de Produtos",
          icon: "pi pi-tags",
          to: "/app/grupo",
          Nivel: [20],
        },
        {
          label: "Produtos",
          icon: "pi pi-mobile",
          to: "/app/produto",
          Nivel: [20],
        },
        {
          label: "Vendedores",
          icon: "pi pi-briefcase",
          to: "/app/vendedor",
          Nivel: [20],
        },
      ],
    },
    {
      label: "Estoque",
      icon: "bi bi-boxes",
      Nivel: [20],
      children: [
        {
          label: "Estoque",
          icon: "pi pi-box",
          to: "/app/estoque",
          Nivel: [20],
        },
        {
          label: "Inventário",
          icon: "pi pi-check-square",
          to: "/app/inventario",
          Nivel: [20],
        },
      ],
    },
    {
      label: "Relatórios",
      icon: "pi pi-chart-bar",
      Nivel: [10, 20],
      children: [
        {
          label: "Status das Filiais",
          icon: "pi pi-sitemap",
          to: "/app/relatorio/statusfiliais",
          Nivel: [10, 20],
        },
        {
          label: "Resumo por Filial",
          icon: "pi pi-building",
          to: "/app/relatorio/filial",
          Nivel: [10, 20],
        },
        {
          label: "Vendas por Loja",
          icon: "pi pi-shopping-cart",
          to: "/app/vendas",
          Nivel: [10, 20],
        },
        {
          label: "Vendas por Vendedor",
          icon: "pi pi-user",
          to: "/app/vendasVendador",
          Nivel: [10, 20],
        },
        {
          label: "Vendas por Produto",
          icon: "pi pi-box", // Ícone de produtos
          to: "/app/vendasPorProduto",
          Nivel: [10, 20]
        },
      ],
    },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  const ValidacaoAcesso = (acessoNecessario, nivelAcesso) =>
    acessoNecessario.some((item) => nivelAcesso.includes(item));

  useEffect(() => {
    if (auth.isAuthenticated()) {
      setNivelUser(auth.GetAccessLevel());
    }
  }, [auth]);

  return (
    <nav className="layout-sidebar">
      <div className="layout-box-menus">
        <ul className="layout-menu">
          {items.map(
            (item) =>
              ValidacaoAcesso(item.Nivel, NivelUser) && (
                <li key={item.label} className="layout-menu-item">
                  {!item.children ? (
                    <button
                      type="button"
                      className="button-none"
                      onClick={() => handleNavigation(item.to)}
                    >
                      <i className={item.icon} />
                      <span>{item.label}</span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="button-none"
                      onClick={() => {
                        if (ItemSelect === item.label) {
                          setShow(false);
                          if (Show === false) setShow(true);
                        } else {
                          setShow(true);
                          setListaSubitem(item.children);
                        }
                        setItemSelect(item.label);
                      }}
                    >
                      <i className={item.icon} />
                      <span>{item.label}</span>
                    </button>
                  )}
                </li>
              )
          )}
          <li className="text-center"><small>V. {versaoSistema}</small></li>
        </ul>
      </div>
      <div
        className="layout-box-menus"
        style={
          !Show
            ? { transform: "translateX(-130px)" }
            : { transform: "translateX(100px)" }
        }
      >
        <ul className="sub-menu">
          {ListaSubitem.map(
            (item) =>
              ValidacaoAcesso(item.Nivel, NivelUser) && (
                <li key={item.label} className="layout-menu-item">
                  <Link to={item.to}>
                    <i className={item.icon} />
                    {item.label}
                  </Link>
                </li>
              )
          )}
        </ul>
      </div>
    </nav>
  );
};

Sidebar.propTypes = {
  Show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default Sidebar;
