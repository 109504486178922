import FormPontoVenda from "components/FormPontoVenda";

import useQuery from "utils/useQuery";

const EditarPontoVenda = () => {
  const query = useQuery();
  return (
    <div className="card">
      <FormPontoVenda PontoVendaId={query.get("id")} />
    </div>
  );
};

export default EditarPontoVenda;
