import { useState } from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

const EmailModule = ({ ListaEmail, setListaEmail }) => {
  const [Email, setEmail] = useState({
    apelido: "",
    emailDaPessoa: ""
  });

  const HandleChangeEmail = (event) => {
    const { name, value } = event.target;

    setEmail({ ...Email, [name]: value });
  };

  const AddEmail = () => {
    try {
      const newLista = [];
      ListaEmail.forEach((e) => {
        newLista.push(e);
      });
      newLista.push({ ...Email, indice: newLista.length });
      setListaEmail(newLista);
      setEmail({
        apelido: "",
        emailDaPessoa: ""
      });
    } catch (error) {
      console.error(error);
    }
  };

  const RemoverEmail = (rowData) => {
    try {
      const newLista = [];
      ListaEmail.forEach((e, i) => {
        if (e.indice !== rowData.indice) newLista.push({ ...e, indice: i });
      });
      setListaEmail(newLista);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="col-md-6 mb-3">
        <label htmlFor="Email-apelido" className="form-label">
          Apelido do E-mail
        </label>
        <InputText
          id="Email-apelido"
          name="apelido"
          value={Email.apelido}
          className="form-control"
          onChange={HandleChangeEmail}
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="Email-ddd" className="form-label">
          Email
        </label>
        <InputText
          id="Email-ddd"
          name="emailDaPessoa"
          value={Email.emailDaPessoa}
          className="form-control"
          onChange={HandleChangeEmail}
        />
      </div>

      <div className="col-md-6 mb-3">
        <div className="h-100 d-flex align-items-end">
          <Button
            type="button"
            label="Adicionar E-mail"
            className="btn btn-primary"
            onClick={AddEmail}
          />
        </div>
      </div>

      <div className="col-12">
        <DataTable value={ListaEmail} emptyMessage="Nenhum E-mail">
          <Column field="apelido" header="Apelido" />
          <Column field="emailDaPessoa" header="E-mail" />
          <Column
            header="Ações"
            body={(rowData) => (
              <Button
                className="btn btn-tabela"
                type="button"
                icon="bi bi-trash"
                onClick={() => RemoverEmail(rowData)}
              />
            )}
          />
        </DataTable>
      </div>
    </>
  );
};

EmailModule.propTypes = {
  ListaEmail: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      apelido: PropTypes.string,
      emailDaPessoa: PropTypes.string
    })
  ).isRequired,
  setListaEmail: PropTypes.func.isRequired
};

export default EmailModule;
