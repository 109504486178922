import React from "react";

const SeusDados = () => (
  <div className="container mt-5">
    <h1>Editar Dados</h1>
    <form>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          E-mail
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="Seu e-mail"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="telefone" className="form-label">
          Telefone
        </label>
        <input
          type="tel"
          className="form-control"
          id="telefone"
          placeholder="Seu telefone"
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Salvar Alterações
      </button>
    </form>
  </div>
);

export default SeusDados;
