import React, { useState, useEffect } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Api from "utils/Api";
import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";
import ExportMonetaryToExcel from "components/ExportToExcel/ExportMonetaryToExcel";

const RelatorioImplantacaoVendas = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const notify = useNotification();

  const [relatorio, setRelatorio] = useState([]);
  const [filters, setFilters] = useState(null);

  const [totalLojasAptas, setTotalLojasAptas] = useState(0);
  const [totalLojasNaoUtilizando, setTotalLojasNaoUtilizando] = useState(0);

  const carregarRelatorio = async () => {
    try {
      setLoading(true);
      const Requisicao = new Api();
      const resposta = await Requisicao.Get({
        endpoint: "/Venda/ObterRelatorioImplantacaoVendas", // Aqui seria o endpoint do relatório
        config: auth.GetHeaders(),
      });
      if (resposta.codigoEstadoHttp === 200) {
        const dados = resposta.objetoResposta;
        setRelatorio(dados);

        // Calcular total de lojas aptas
        const lojasAptas = dados.filter(
          (loja) => loja.status === "PDV Operando: sem pendências"
        ).length;
        setTotalLojasAptas(lojasAptas);

        // Calcular total de lojas que não estão utilizando o sistema
        const lojasNaoUtilizando = dados.filter(
          (loja) => loja.valorLiquidoVendas < 100
        ).length;
        setTotalLojasNaoUtilizando(lojasNaoUtilizando);
      } else {
        notify({
          type: "error",
          message: "Erro ao carregar o relatório.",
        });
      }
    } catch (error) {
      console.error(error);
      notify({
        type: "error",
        message: "Erro ao carregar o relatório.",
      });
    } finally {
      setLoading(false);
    }
  };

  const InitFilters = () => {
    setFilters({
      CodigoLoja: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      Status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      QuantidadeVendas: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
  };

  // Função para formatar data no formato dd/MM/yyyy
  const formatarData = (data) => {
    const dataObj = new Date(data);
    return dataObj.toLocaleDateString("pt-BR", { timeZone: "UTC" });
  };

  // Função para formatar o valor no formato pt-BR com duas casas decimais
  const formatarValor = (valor) =>
    valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });

  useEffect(() => {
    carregarRelatorio();
    InitFilters();
  }, []);

  return (
    <div className="card p-p-3">
      <div className="d-flex justify-content-end mb-3 p-3">
        {/* Componente de Exportação para Excel */}
        <ExportMonetaryToExcel data={relatorio} fileName="RelatorioImplantacaoVendas" />
      </div>

      {/* Exibindo os totais */}
      <div className="mb-3" style={{ padding: '16px' }}>
        <h5>Total de lojas aptas: {totalLojasAptas}</h5>
        <h5>Lojas com vendas abaixo de R$100: {totalLojasNaoUtilizando}</h5>
      </div>

      <div style={{ padding: '16px' }}>
        <DataTable
          value={relatorio}
          filters={filters}
          emptyMessage="Nenhum relatório encontrado."
          className="p-pt-3 p-pb-2"
        >
          <Column field="lojaId" header="Loja ID" />
          <Column field="operador" header="Operador" sortable />
          <Column field="codigoLoja" header="Código Loja" sortable />
          <Column field="status" header="Status" sortable />
          <Column
            field="dataBalcaoPdvOperante"
            header="Data PDV Operante"
            body={(rowData) => formatarData(rowData.dataBalcaoPdvOperante)}
            sortable
          />
          <Column field="quantidadeVendas" header="Quantidade de Vendas" sortable />
          <Column
            field="valorLiquidoVendas"
            header="Valor Líquido Vendas"
            body={(rowData) => formatarValor(rowData.valorLiquidoVendas)}
            sortable
          />
          <Column
            field="dataUltimaVenda"
            header="Data Última Venda"
            body={(rowData) => formatarData(rowData.dataUltimaVenda)}
            sortable
          />
        </DataTable>
      </div>
    </div>
  );
};

export default RelatorioImplantacaoVendas;
