import { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import Api from "utils/Api";

const ListaStatusFiliais = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();

  const [Lista, setLista] = useState([]);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [dateAberturaRange, setDateAberturaRange] = useState(null);
  const [dateFechamentoRange, setDateFechamentoRange] = useState(null);

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: "/AberturaFechamentoCaixa/ObterStatusFiliais",
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        setLista(resposta.objetoResposta);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nome: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      nomeFantasia: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      cnpj: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      dataUltimaAbertura: {
        value: null,
        matchMode: FilterMatchMode.BETWEEN
      },
      dataUltimoFechamento: {
        value: null,
        matchMode: FilterMatchMode.BETWEEN
      }
    });
    setGlobalFilterValue("");
    setDateAberturaRange(null);
    setDateFechamentoRange(null);
  };

  const ClearFilter = () => {
    InitFilters();
  };

  const OnGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      const thisFilters = { ...filters };

      thisFilters.global.value = value;

      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  const onDateAberturaChange = (e) => {
    const { value } = e;
    setDateAberturaRange(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataUltimaAbertura: {
        value,
        matchMode: FilterMatchMode.BETWEEN
      }
    }));
  };

  const onDateFechamentoChange = (e) => {
    const { value } = e;
    setDateFechamentoRange(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataUltimoFechamento: {
        value,
        matchMode: FilterMatchMode.BETWEEN
      }
    }));
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage();
    }
    InitFilters();
  }, [auth]);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
  };

  return (
    <div className="card p-3">
      <div className="d-flex justify-content-between mb-3">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Limpar filtro"
          outlined
          onClick={ClearFilter}
        />
        <div className="d-flex gap-3 align-items-center">
          <span>Data de Abertura:</span>
          <Calendar
            value={dateAberturaRange}
            onChange={onDateAberturaChange}
            selectionMode="range"
            readOnlyInput
            placeholder="Selecionar período"
            dateFormat="dd/mm/yy"
            locale="pt-BR"
          />
          <span>Data de Fechamento:</span>
          <Calendar
            value={dateFechamentoRange}
            onChange={onDateFechamentoChange}
            selectionMode="range"
            readOnlyInput
            placeholder="Selecionar período"
            dateFormat="dd/mm/yy"
            locale="pt-BR"
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={OnGlobalFilterChange}
              placeholder="Pesquisa Global"
            />
          </span>
        </div>
      </div>
      <DataTable
        value={Lista}
        stripedRows
        paginator
        rows={20}
        tableStyle={{ minWidth: "50rem" }}
        emptyMessage="Nenhuma empresa encontrada."
        globalFilterFields={["nomeFantasia", "razaoSocial", "cnpj"]}
        filters={filters}
      >
        <Column field="nomeFantasia" header="Nome" sortable style={{ width: '20%' }}/>
        <Column field="razaoSocial" header="Razão Social" sortable style={{ width: '20%' }} />
        <Column field="cnpj" header="CNPJ" sortable />
        <Column
          field="dataUltimaAbertura"
          header="Data Última Abertura"
          body={(rowData) => formatDate(rowData.dataUltimaAbertura)}
          sortable
          filter
          filterField="dataUltimaAbertura"
        />
        <Column
          field="dataUltimoFechamento"
          header="Data Último Fechamento"
          body={(rowData) => formatDate(rowData.dataUltimoFechamento)}
          sortable
          filter
          filterField="dataUltimoFechamento"
        />
        <Column
          field="caixaAberto"
          header="Caixa Aberto"
          sortable
          body={(rowData) => (rowData.caixaAberto ? "Sim" : "Não")}
        />
      </DataTable>
    </div>
  );
};

export default ListaStatusFiliais;
