import React from "react";

import MaskUtil from "utils/MaskUtil";

const Notifications = () => {
  // Suponha que você tenha uma lista de notificações
  const notifications = [
    { id: 1, message: "Nova notificação 1", date: "2024-03-04" },
    { id: 2, message: "Nova notificação 2", date: "2024-03-03" }
    // Adicione mais notificações conforme necessário
  ];

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h2>Notificações Recentes</h2>
          <ul className="list-group">
            {notifications.map((notification) => (
              <li key={notification.id} className="list-group-item">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <p className="fs-5 m-0">{notification.message}</p>
                  <p className="fs-6 m-0 text-muted">
                    {MaskUtil.applyDataMask(notification.date)}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
