import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Button } from 'primereact/button'; // Certifique-se de que o PrimeReact está importado corretamente

const ExportMonetaryToExcel = ({ data, fileName }) => {
  const exportToExcel = () => {
    if (!data || data.length === 0) {
      console.error('Nenhum dado para exportar');
      return;
    }

    // Função para formatar valores monetários corretamente
    const formatMonetary = (value) => {
      if (typeof value === 'string' && value.includes('R$')) {
        return value.replace('.', ','); // Apenas substitui o ponto pela vírgula
      }
      return value;
    };

    // Função para formatar data e hora no formato DD/MM/YYYY HH:MM:SS
    const formatDateTime = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };

    // Limpa e formata os dados para o Excel
    const filteredData = data.map((row) => {
      const cleanedRow = {};
      Object.keys(row).forEach((key) => {
        const value = row[key];
        // Formata valores monetários e datas
        if (typeof value === 'string' && value.includes('R$')) {
          cleanedRow[key] = formatMonetary(value); // Formatação monetária
        } else if (key.toLowerCase().includes('data') && value) {
          cleanedRow[key] = formatDateTime(value); // Formatação de data e hora
        } else {
          cleanedRow[key] = value; // Manter os outros valores como estão
        }
      });
      return cleanedRow;
    });

    const ws = XLSX.utils.json_to_sheet(filteredData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Relatório');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <Button onClick={exportToExcel} className="btn btn-primary">
      Exportar para Excel
    </Button>
  );
};

export default ExportMonetaryToExcel;
