import Barcode from "react-barcode";

import PropTypes from "prop-types";

import { Button } from "primereact/button";

const CodigoEmBarras = ({ barcodeData }) => {
  const printBarcode = async () => {
    // Abrir a rota em uma nova janela
    const printWindow = window.open(
      `/app/produto/codigoDeBarra?codigo=${barcodeData}`,
      "_blank"
    );

    // Esperar um momento para garantir que a janela seja carregada
    setTimeout(() => {
      // Imprimir o conteúdo da janela
      printWindow.print();
      printWindow.close();
    }, 1000);
  };

  return (
    <div>
      {barcodeData !== null && (
        <div>
          <Barcode value={barcodeData} />
          <Button
            type="button"
            icon="pi pi-print"
            label="Imprimir Código de Barras"
            onClick={printBarcode}
          />
        </div>
      )}
    </div>
  );
};

CodigoEmBarras.propTypes = { barcodeData: PropTypes.number };

CodigoEmBarras.defaultProps = {
  barcodeData: null
};

export default CodigoEmBarras;
