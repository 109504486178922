import FormGrupo from "components/FormGrupo";

import useQuery from "utils/useQuery";

const EditarGrupoProduto = () => {
  const query = useQuery();
  return (
    <div className="card">
      <FormGrupo GrupoId={query.get("id")} />
    </div>
  );
};

export default EditarGrupoProduto;
