import FormContas from "components/FormContas";

import useQuery from "utils/useQuery";

const EditarContas = () => {
  const query = useQuery();

  return (
    <div className="card">
      <FormContas ContaId={query.get("id")} />
    </div>
  );
};

export default EditarContas;
