import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";
import TrataRetornoApi from "utils/TrataRetornoApi";

const ListaOperadores = () => {
  const tratarRetornoApi = new TrataRetornoApi();
  const { setLoading } = useLoading();
  const auth = useAuth();
  const requisicao = new Api();
  const exibirNotificacao = useNotification();

  const [Lista, setLista] = useState([]);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const carregarDados = async () => {
    setLoading(true);

    try {
      const resposta = await requisicao.Get({
        endpoint: `/Operador`,
        config: auth.GetHeaders()
      });

      if (resposta?.codigoEstadoHttp === 200) {
        setLista(resposta.objetoResposta);
      }
    } catch (error) {
      exibirNotificacao({
        type: "erro",
        message: tratarRetornoApi.obterMensagemErro(error)
      });
    } finally {
      setLoading(false);
    }
  };

  const OnGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      const thisFilters = { ...filters };

      thisFilters.global.value = value;

      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nome: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      nomeFantasia: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    });
    setGlobalFilterValue("");
  };

  const ClearFilter = () => {
    InitFilters();
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      carregarDados();
    }
    InitFilters();
  }, [auth]);

  return (
    <div className="card p-3">
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex flex-row gap-3">
          <Link to="/app/operador/new" className="btn btn-primary">
            Criar
          </Link>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Limpar filtro"
            outlined
            onClick={ClearFilter}
          />
        </div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={OnGlobalFilterChange}
            placeholder="Pesquisa Global"
          />
        </span>
      </div>
      <DataTable
        value={Lista}
        stripedRows
        paginator
        rows={20}
        tableStyle={{ minWidth: "50rem" }}
        emptyMessage="Nenhuma empresa encontrada."
        filters={filters}
      >
        <Column
          field="dataCriacao"
          header="Data Criação"
          sortable
          body={(rowData) =>
            MaskUtil.applyDataAndHoraMask(rowData.dataCriacao)
          }
        />
        <Column field="id" header="Id" sortable />
        <Column field="pessoa.cpfOuCnpj" header="CPF ou CNPJ" sortable />
        <Column
          field="pessoa.nomeCompletoOuRazaoSocial"
          header="Nome"
          sortable
        />
        <Column
          field="pessoa.apelidoOuNomeFantasia"
          header="Apelido"
          sortable
        />
        <Column
          header="Ações"
          body={(rowData) => (
            <div>
              <div className="d-flex flex-row gap-3">
                <Link
                  to={`/app/Operador/Editar?id=${rowData.id}`}
                  className="btn btn-tabela"
                >
                  <i className="bi bi-pencil" />
                </Link>
              </div>
            </div>
          )}
        />
      </DataTable>
    </div>
  );
};

export default ListaOperadores;
