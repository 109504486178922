import { useState } from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import MaskUtil from "utils/MaskUtil";

const TelefoneModule = ({ LitasTelefone, setLitasTelefone }) => {
  const [Telefone, setTelefone] = useState({
    apelido: "",
    ddd: 0,
    numero: ""
  });

  const HandleChangeTelefone = (event) => {
    const { name, value } = event.target;

    if (name === "numero") {
      const maskedValue = MaskUtil.applyTelefoneMask(value);
      setTelefone({ ...Telefone, [name]: maskedValue });
    } else if (name === "ddd") {
      setTelefone({ ...Telefone, [name]: Number(value.replace(/\D/g, "")) });
    } else {
      setTelefone({ ...Telefone, [name]: value });
    }
  };

  const AddTelefone = () => {
    try {
      const newLista = [];
      LitasTelefone.forEach((e) => {
        newLista.push(e);
      });
      newLista.push({ ...Telefone, indice: newLista.length });
      setLitasTelefone(newLista);
      setTelefone({
        apelido: "",
        ddd: 0,
        numero: ""
      });
    } catch (error) {
      console.error(error);
    }
  };

  const RemoverTelefone = (rowData) => {
    try {
      const newLista = [];
      LitasTelefone.forEach((e, i) => {
        if (e.indice !== rowData.indice) newLista.push({ ...e, indice: i });
      });
      setLitasTelefone(newLista);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="col-md-6 mb-3">
        <label htmlFor="telefones-apelido" className="form-label">
          Apelido do telefone
        </label>
        <InputText
          id="telefones-apelido"
          name="apelido"
          className="form-control"
          value={Telefone.apelido}
          onChange={HandleChangeTelefone}
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="telefones-ddd" className="form-label">
          DDD
        </label>
        <InputText
          id="telefones-ddd"
          name="ddd"
          value={Telefone.ddd}
          className="form-control"
          onChange={HandleChangeTelefone}
        />
      </div>

      <div className="col-md-6 mb-3">
        <label htmlFor="telefones-numero" className="form-label">
          Número
        </label>
        <InputText
          id="telefones-numero"
          name="numero"
          value={Telefone.numero}
          className="form-control"
          onChange={HandleChangeTelefone}
        />
      </div>

      <div className="col-12" style={{ marginBottom: "15px" }}>
        <div className="h-100 d-flex align-items-end">
          <Button
            type="button"
            label="Adicionar Telefone"
            className="btn btn-primary"
            onClick={AddTelefone}
          />
        </div>
      </div>

      <div className="col-12">
        <DataTable value={LitasTelefone} emptyMessage="Nenhum Telefone">
          <Column field="apelido" header="Apelido" />
          <Column field="ddd" header="DDD" />
          <Column field="numero" header="Número" />
          <Column
            header="Ações"
            body={(rowData) => (
              <Button
                className="btn btn-tabela"
                type="button"
                icon="bi bi-trash"
                onClick={() => RemoverTelefone(rowData)}
              />
            )}
          />
        </DataTable>
      </div>
    </>
  );
};

TelefoneModule.propTypes = {
  LitasTelefone: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      apelido: PropTypes.string,
      ddd: PropTypes.number,
      numero: PropTypes.string
    })
  ).isRequired,
  setLitasTelefone: PropTypes.func.isRequired
};

export default TelefoneModule;
