import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

const FormGrupo = ({ GrupoId }) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();
  const navigate = useNavigate();

  const [Codigo, setCodigo] = useState(null);
  const [Descricao, setDescricao] = useState("");
  const [AliquotaICMS, setAliquotaICMS] = useState(null);
  const [Reducao, setReducao] = useState(null);
  const [PisEntradaAliquota, setPisEntradaAliquota] = useState(null);
  const [PisSaidaAliquota, setPisSaidaAliquota] = useState(null);
  const [CofinsEntradaAliquota, setCofinsEntradaAliquota] = useState(null);
  const [CofinsSaidaAliquota, setCofinsSaidaAliquota] = useState(null);

  const [SelectedUnidade, setSelectedUnidade] = useState(null);
  const [SelectedProduto, setSelectedProduto] = useState(null);
  const [SelectedClasseTributacao, setSelectedClasseTributacao] =
    useState(null);
  const [SelectedTipoTributacaoCst, setSelectedTipoTributacaoCst] =
    useState(null);
  const [SelectedCest, setSelectedCest] = useState(null);
  const [SelectedOrigem, setSelectedOrigem] = useState(null);
  const [SelectedNCM, setSelectedNCM] = useState(null);
  const [SelectedPisEntrada, setSelectedPisEntrada] = useState(null);
  const [SelectedPisSaida, setSelectedPisSaida] = useState(null);
  const [SelectedCofinsEntrada, setSelectedCofinsEntrada] = useState(null);
  const [SelectedCofinsSaida, setSelectedCofinsSaida] = useState(null);

  const [ListaUnidade, setListaUnidade] = useState([]);
  const [ListaPrudito, setListaPrudito] = useState([]);
  const [ListaClasseTributacao, setListaClasseTributacao] = useState([]);
  const [ListaTipoTributacaoCst, setListaTipoTributacaoCst] = useState([]);
  const [ListaCest, setListaCest] = useState([]);
  const [ListaOrigem, setListaOrigem] = useState([]);
  const [ListaNCM, setListaNCM] = useState([]);
  const [ListaEntrada, setListaEntrada] = useState([]);
  const [ListaSaida, setListaSaida] = useState([]);

  const [filteredCountries, setFilteredCountries] = useState(null);

  const [Data, setData] = useState({});

  const CriarGrupo = async () => {
    try {
      const data = {
        descricao: Descricao,
        tipoUnidadeId: SelectedUnidade.code,
        tipoProdutoId: SelectedProduto.code,
        tributo: {
          cstCodigoDeSituacaoTributariaId: SelectedClasseTributacao?.code,
          tipoTributacaoCstId: SelectedTipoTributacaoCst?.code,
          tribEstCestId: SelectedCest?.code,
          tribEstOrigemId: SelectedOrigem?.code,
          tribEstNcmId: SelectedNCM?.code,
          tribEstAliquotaIcms: AliquotaICMS,
          tribEstPorcentagemReducaoIcms: Reducao,
          tribFedPisAliquotaEntrada: PisEntradaAliquota,
          tribFedPisAliquotaSaida: PisSaidaAliquota,
          tribFedPisTipoEntradaId: SelectedPisEntrada?.code,
          tribFedPisTipoSaidaId: SelectedPisSaida?.code,
          tribFedCofinsAliquotaEntrada: CofinsEntradaAliquota,
          tribFedCofinsAliquotaSaida: CofinsSaidaAliquota,
          tribFedCofinsTipoEntradaId: SelectedCofinsEntrada?.code,
          tribFedCofinsTipoSaidaId: SelectedCofinsSaida?.code
        }
      };

      await Requicicao.Post({
        endpoint: "/GrupoProduto",
        data,
        config: auth.GetHeaders()
      });
      notify({
        type: "sucesso",
        message: "Grupo criado com sucesso"
      });
      navigate("/app/grupo");
    } catch (erro) {
      notify({
        type: "erro",
        message:
          "Erro ao tentar executar esta ação. Por favor, entre em contato com o suporte."
      });
      console.error(erro);
    }
  };

  const AtulizarGrupo = async () => {
    try {
      const data = {
        ...Data,
        ...{
          descricao: Descricao,
          tipoUnidadeId: SelectedUnidade.code,
          tipoProdutoId: SelectedProduto.code,
          tributo: {
            ...Data.tributo,
            ...{
              cstCodigoDeSituacaoTributariaId: SelectedClasseTributacao.code,
              tipoTributacaoCstId: SelectedTipoTributacaoCst.code,
              tribEstCestId: SelectedCest.code,
              tribEstOrigemId: SelectedOrigem.code,
              tribEstNcmId: SelectedNCM.code,
              tribEstAliquotaIcms: AliquotaICMS,
              tribEstPorcentagemReducaoIcms: Reducao,
              tribFedPisAliquotaEntrada: PisEntradaAliquota,
              tribFedPisAliquotaSaida: PisSaidaAliquota,
              tribFedPisTipoEntradaId: SelectedPisEntrada.code,
              tribFedPisTipoSaidaId: SelectedPisSaida.code,
              tribFedCofinsAliquotaEntrada: CofinsEntradaAliquota,
              tribFedCofinsAliquotaSaida: CofinsSaidaAliquota,
              tribFedCofinsTipoEntradaId: SelectedCofinsEntrada.code,
              tribFedCofinsTipoSaidaId: SelectedCofinsSaida.code
            }
          }
        }
      };

      await Requicicao.Put({
        endpoint: "/GrupoProduto",
        data,
        config: auth.GetHeaders()
      });
      notify({
        type: "sucesso",
        message: "Atualização realizada com sucesso."
      });
    } catch (error) {
      console.error(error);
      notify({
        type: "erro",
        message:
          "Erro ao tentar executar esta ação. Por favor, entre em contato com o suporte."
      });
    }
  };

  const Submit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (GrupoId === "") await CriarGrupo();
      else await AtulizarGrupo();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const search = (event) => {
    try {
      // Timeout to emulate a network connection
      setTimeout(() => {
        let thisfilteredCountries;

        if (!event.query.trim().length) {
          thisfilteredCountries = [...ListaNCM];
        } else {
          thisfilteredCountries = ListaNCM.filter((country) =>
            country.name.toLowerCase().includes(event.query.toLowerCase())
          );
        }
        setFilteredCountries(thisfilteredCountries);
      }, 500);
    } catch (error) {
      console.error(error);
    }
  };

  const StateDataPage = async () => {
    try {
      setLoading(true);

      /**
       * Get Tipo Unidade
       */
      const ObterTipoUnidade = await Requicicao.Get({
        endpoint: "/TipoAndStatus/ObterTipoUnidade",
        config: auth.GetHeaders()
      });
      const TipoUnidade = [];
      ObterTipoUnidade.objetoResposta.forEach((element) => {
        TipoUnidade.push({ name: element.descricao, code: element.id });
      });
      setListaUnidade(TipoUnidade);

      /**
       * Get Tipo Produto
       */
      const ObterTipoProduto = await Requicicao.Get({
        endpoint: "/TipoAndStatus/ObterTipoProduto",
        config: auth.GetHeaders()
      });
      const TipoProduto = [];
      ObterTipoProduto.objetoResposta.forEach((element) => {
        TipoProduto.push({ name: element.descricao, code: element.id });
      });
      setListaPrudito(TipoProduto);

      /**
       * Get Classe de Tributação
       */
      const ObterClasseTributacao = await Requicicao.Get({
        endpoint: "/TipoAndStatus/ObterCstCodigoDeSituacaoTributaria",
        config: auth.GetHeaders()
      });
      const ClasseTributacao = [];
      ObterClasseTributacao.objetoResposta.forEach((element) => {
        ClasseTributacao.push({
          name: element.descricao,
          code: element.id
        });
      });
      setListaClasseTributacao(ClasseTributacao);

      /**
       * Get Tributação
       */
      const ObterTributacao = await Requicicao.Get({
        endpoint: "/TipoAndStatus/ObterTipoTributacaoCst",
        config: auth.GetHeaders()
      });
      const TipoTributacao = [];
      ObterTributacao.objetoResposta.forEach((element) => {
        TipoTributacao.push({
          name: element.descricao,
          code: element.id
        });
      });
      setListaTipoTributacaoCst(TipoTributacao);

      /**
       * Get CEST
       */
      const ObterCest = await Requicicao.Get({
        endpoint:
          "/TipoAndStatus/ObterCestCodigoEspecificadorDaSubstituicaoTributaria",
        config: auth.GetHeaders()
      });
      const Cest = [];
      ObterCest.objetoResposta.forEach((element) => {
        Cest.push({
          name: element.descricao.substring(0, 250),
          code: element.id
        });
      });
      setListaCest(Cest);

      /**
       * Get Origem
       */
      const ObterOrigem = await Requicicao.Get({
        endpoint: "/TipoAndStatus/ObterTributoEstadualOrigem",
        config: auth.GetHeaders()
      });
      const Origem = [];
      ObterOrigem.objetoResposta.forEach((element) => {
        Origem.push({
          name: element.descricao.substring(0, 120),
          code: element.id
        });
      });
      setListaOrigem(Origem);

      /**
       * NCM
       */
      const ObterNcm = await Requicicao.Get({
        endpoint: "/TipoAndStatus/ObterNcmNomenclaturaComumDoMercosul",
        config: auth.GetHeaders()
      });
      const Ncm = [];
      ObterNcm.objetoResposta.forEach((element) => {
        Ncm.push({
          name: element.descricao
            .replace("-", "")
            .replace("- ", "")
            .replace(/<\/?i>/g, "")
            .substring(0, 160),
          code: element.id
        });
      });
      setListaNCM(Ncm);

      /**
       * Get Entrada
       */
      const ObterEntrada = await Requicicao.Get({
        endpoint: "/TipoAndStatus/ObterTipoPisCofinsNaEntrada",
        config: auth.GetHeaders()
      });
      const Entrada = [];
      ObterEntrada.objetoResposta.forEach((element) => {
        Entrada.push({
          name: element.descricao,
          code: element.id
        });
      });
      setListaEntrada(Entrada);

      /**
       * Get Saída
       */
      const ObterSaida = await Requicicao.Get({
        endpoint: "/TipoAndStatus/ObterTipoPisCofinsNaSaida",
        config: auth.GetHeaders()
      });
      const Saida = [];
      ObterSaida.objetoResposta.forEach((element) => {
        Saida.push({
          name: element.descricao,
          code: element.id
        });
      });
      setListaSaida(Saida);

      /**
       * Get Grupo
       */
      if (GrupoId !== "") {
        const { objetoResposta } = await Requicicao.Get({
          endpoint: "/GrupoProduto/ObterPorId",
          params: { id: GrupoId },
          config: auth.GetHeaders()
        });

        setData(objetoResposta);

        setCodigo(objetoResposta.id);

        setDescricao(objetoResposta.descricao);

        const tipoUnidade = TipoUnidade.filter(
          (x) => x.code === objetoResposta.tipoUnidadeId
        );
        setSelectedUnidade(tipoUnidade[0]);

        const tipoProduto = TipoProduto.filter(
          (x) => x.code === objetoResposta.tipoProdutoId
        );
        setSelectedProduto(tipoProduto[0]);

        const classeTributacao = ClasseTributacao.filter(
          (x) =>
            x.code === objetoResposta.tributo.cstCodigoDeSituacaoTributariaId
        );
        setSelectedClasseTributacao(classeTributacao[0]);

        const tipoTributacaoCst = TipoTributacao.filter(
          (x) => x.code === objetoResposta.tributo.tipoTributacaoCstId
        );
        setSelectedTipoTributacaoCst(tipoTributacaoCst[0]);

        const cest = Cest.filter(
          (x) => x.code === objetoResposta.tributo.tribEstCestId
        );
        setSelectedCest(cest[0]);

        const origem = Origem.filter(
          (x) => x.code === objetoResposta.tributo.tribEstOrigemId
        );
        setSelectedOrigem(origem[0]);

        setAliquotaICMS(objetoResposta.tributo.tribEstAliquotaIcms);

        const ncm = Ncm.filter(
          (x) => x.code === objetoResposta.tributo.tribEstNcmId
        );
        setSelectedNCM(ncm[0]);

        setReducao(objetoResposta.tributo.tribEstPorcentagemReducaoIcms);

        const pisEntrada = Entrada.filter(
          (x) => x.code === objetoResposta.tributo.tribFedPisTipoEntradaId
        );
        setSelectedPisEntrada(pisEntrada[0]);

        setPisEntradaAliquota(objetoResposta.tributo.tribFedPisAliquotaEntrada);

        const pisSaida = Saida.filter(
          (x) => x.code === objetoResposta.tributo.tribFedPisTipoSaidaId
        );
        setSelectedPisSaida(pisSaida[0]);

        setPisSaidaAliquota(objetoResposta.tributo.tribFedPisAliquotaSaida);

        const cofinsEntrada = Entrada.filter(
          (x) => x.code === objetoResposta.tributo.tribFedCofinsTipoEntradaId
        );
        setSelectedCofinsEntrada(cofinsEntrada[0]);

        setCofinsEntradaAliquota(
          objetoResposta.tributo.tribFedCofinsAliquotaEntrada
        );

        const cofinsSaida = Saida.filter(
          (x) => x.code === objetoResposta.tributo.tribFedCofinsTipoSaidaId
        );
        setSelectedCofinsSaida(cofinsSaida[0]);

        setCofinsSaidaAliquota(
          objetoResposta.tributo.tribFedCofinsAliquotaSaida
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage();
    }
  }, [auth]);

  return (
    <form onSubmit={Submit} className="card-body">
      <div className="row">
        {Codigo !== null && (
          <div className="col-sm-6 col-md-3 mb-3">
            <label htmlFor="codigo" className="form-label">
              Código
            </label>
            <InputText
              id="codigo"
              name="codigo"
              className="form-control"
              disabled
              value={Codigo}
            />
          </div>
        )}
        <div
          className={`col-sm-6 mb-3 ${
            Codigo !== null ? "col-md-3" : "col-md-4"
          }`}
        >
          <label htmlFor="descricao" className="form-label">
            Descrição
          </label>
          <InputText
            id="descricao"
            name="descricao"
            className="form-control"
            value={Descricao}
            onChange={(e) => setDescricao(e.target.value)}
          />
        </div>
        <div
          className={`col-sm-6 mb-3 ${
            Codigo !== null ? "col-md-3" : "col-md-4"
          }`}
        >
          <div className="form-group">
            <label className="form-label">Unidade</label>
            <Dropdown
              id="unidade"
              value={SelectedUnidade}
              onChange={(e) => setSelectedUnidade(e.value)}
              options={ListaUnidade}
              optionLabel="name"
              className="select"
              filter
            />
          </div>
        </div>
        <div
          className={`col-sm-6 mb-3 ${
            Codigo !== null ? "col-md-3" : "col-md-4"
          }`}
        >
          <div className="form-group">
            <label className="form-label">Tipo Produto</label>
            <Dropdown
              value={SelectedProduto}
              onChange={(e) => setSelectedProduto(e.value)}
              options={ListaPrudito}
              optionLabel="name"
              className="select"
              filter
            />
          </div>
        </div>
        <div className="col-12">
          <br />
          <hr />
        </div>
        <div className="col-12">
          <h6>
            <b>Tributos</b>
          </h6>
        </div>
        <div className="col-sm-6 col-md-8 mb-3">
          <div className="form-group">
            <label className="form-label">Classe de Tributação</label>
            <Dropdown
              value={SelectedClasseTributacao}
              onChange={(e) => setSelectedClasseTributacao(e.value)}
              options={ListaClasseTributacao}
              optionLabel="name"
              className="select"
              filter
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4 mb-3">
          <div className="form-group">
            <label className="form-label">Tributação</label>
            <Dropdown
              value={SelectedTipoTributacaoCst}
              onChange={(e) => setSelectedTipoTributacaoCst(e.value)}
              options={ListaTipoTributacaoCst}
              optionLabel="name"
              className="select"
              filter
            />
          </div>
        </div>

        <div className="col-sm-12 col-md-12 mb-3">
          <div className="form-group">
            <label className="form-label">
              CEST - Código Especificador da Substituição Tributária
            </label>
            <Dropdown
              id="CEST"
              value={SelectedCest}
              onChange={(e) => setSelectedCest(e.value)}
              options={ListaCest}
              optionLabel="name"
              className="select"
              filter
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-9 mb-3">
          <div className="form-group">
            <label className="form-label">Origem</label>
            <Dropdown
              id="origem"
              value={SelectedOrigem}
              onChange={(e) => setSelectedOrigem(e.value)}
              options={ListaOrigem}
              optionLabel="name"
              className="select"
              filter
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="aliquotaICMS" className="form-label">
              Alíquota ICMS
            </label>
            <InputNumber
              id="aliquotaICMS"
              name="aliquotaICMS"
              useGrouping={false}
              minFractionDigits={2}
              maxFractionDigits={2}
              locale="pt-br"
              value={AliquotaICMS}
              onChange={(e) => setAliquotaICMS(e.value)}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-9 mb-3">
          <div className="form-group">
            <label htmlFor="NCM" className="form-label">
              NCM
            </label>
            <AutoComplete
              field="name"
              value={SelectedNCM}
              onChange={(e) => setSelectedNCM(e.value)}
              suggestions={filteredCountries}
              completeMethod={search}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="reducao" className="form-label">
              % Redução ICMS
            </label>
            <InputNumber
              id="reducao"
              name="reducao"
              min={0}
              max={100}
              useGrouping={false}
              maxFractionDigits={2}
              minFractionDigits={2}
              locale="pt-br"
              value={Reducao}
              onChange={(e) => setReducao(e.value)}
            />
          </div>
        </div>
        <div className="col-12">
          <hr />
        </div>
        <div className="col-12">
          <h6>
            <b>PIS - CST e Alíquotas</b>
          </h6>
        </div>
        <div className="col-sm-12 col-md-9 mb-3">
          <div className="form-group">
            <label htmlFor="pisEntrada" className="form-label">
              Na Entrada
            </label>
            <Dropdown
              id="pisEntrada"
              value={SelectedPisEntrada}
              onChange={(e) => setSelectedPisEntrada(e.value)}
              options={ListaEntrada}
              optionLabel="name"
              className="select"
              filter
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="pisEntradaAliquota" className="form-label">
              Aliquota
            </label>
            <InputNumber
              id="pisEntradaAliquota"
              name="pisEntradaAliquota"
              useGrouping={false}
              minFractionDigits={2}
              maxFractionDigits={2}
              locale="pt-br"
              value={PisEntradaAliquota}
              onChange={(e) => setPisEntradaAliquota(e.value)}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-9 mb-3">
          <div className="form-group">
            <label htmlFor="pisSaida" className="form-label">
              Na Saída
            </label>
            <Dropdown
              id="pisSaida"
              value={SelectedPisSaida}
              onChange={(e) => setSelectedPisSaida(e.value)}
              options={ListaSaida}
              optionLabel="name"
              className="select"
              filter
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="pisSaidaAliquota" className="form-label">
              Aliquota
            </label>
            <InputNumber
              id="pisSaidaAliquota"
              name="pisSaidaAliquota"
              useGrouping={false}
              minFractionDigits={2}
              maxFractionDigits={2}
              locale="pt-br"
              value={PisSaidaAliquota}
              onChange={(e) => setPisSaidaAliquota(e.value)}
            />
          </div>
        </div>
        <div className="col-12">
          <h6>
            <b>COFINS - CST e Alíquotas</b>
          </h6>
        </div>
        <div className="col-sm-12 col-md-9 mb-3">
          <div className="form-group">
            <label htmlFor="cofinsEntrada" className="form-label">
              Na Entrada
            </label>
            <Dropdown
              id="cofinsEntrada"
              value={SelectedCofinsEntrada}
              onChange={(e) => setSelectedCofinsEntrada(e.value)}
              options={ListaEntrada}
              optionLabel="name"
              className="select"
              filter
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="cofinsEntradaAliquota" className="form-label">
              Aliquota
            </label>
            <InputNumber
              id="cofinsEntradaAliquota"
              name="cofinsEntradaAliquota"
              useGrouping={false}
              minFractionDigits={2}
              maxFractionDigits={2}
              locale="pt-br"
              value={CofinsEntradaAliquota}
              onChange={(e) => setCofinsEntradaAliquota(e.value)}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-9 mb-3">
          <div className="form-group">
            <label htmlFor="cofinsSaida" className="form-label">
              Na Saída
            </label>
            <Dropdown
              id="cofinsSaida"
              value={SelectedCofinsSaida}
              onChange={(e) => setSelectedCofinsSaida(e.value)}
              options={ListaSaida}
              optionLabel="name"
              className="select"
              filter
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="cofinsSaidaAliquota" className="form-label">
              Aliquota
            </label>
            <InputNumber
              id="cofinsSaidaAliquota"
              name="cofinsSaidaAliquota"
              useGrouping={false}
              minFractionDigits={2}
              maxFractionDigits={2}
              locale="pt-br"
              value={CofinsSaidaAliquota}
              onChange={(e) => setCofinsSaidaAliquota(e.value)}
            />
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="w-100 d-flex flex-row-reverse">
            <Button
              type="submit"
              label={GrupoId === "" ? "Salvar" : "Atualizar"}
              className="btn btn-primary"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

FormGrupo.propTypes = { GrupoId: PropTypes.string };

FormGrupo.defaultProps = {
  GrupoId: ""
};

export default FormGrupo;
