import { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator, locale, addLocale } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ptBrLocale from "components/Locale/locales";

import ExportToExcel from "components/ExportToExcel";
import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";
import Api from "utils/Api";

addLocale('pt-BR', ptBrLocale);
locale('pt-BR');

const ListaVendasConsolidadasPorVendedor = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [Lista, setLista] = useState([]);
  const [Colunas, setColunas] = useState([]);
  const [ListaEmpresas, setListaEmpresas] = useState([]);
  const [ListaLojas, setListaLojas] = useState([]);
  const [SelectedEmpresa, setSelectedEmpresa] = useState(null);
  const [SelectedLoja, setSelectedLoja] = useState(null);
  const [filters, setFilters] = useState(null);
  const [dateRange, setDateRange] = useState(() => {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return [firstDayOfMonth, lastDayOfMonth];
  });
  const [view, setView] = useState('date'); // Novo estado para controlar a visão

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      produtoNome: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    });
  };

  const ClearFilter = () => {
    InitFilters();
    setSelectedEmpresa(ListaEmpresas[0] || null);
    setSelectedLoja(ListaLojas[0] || null);
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    setDateRange([firstDayOfMonth, lastDayOfMonth]);
  };

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(date).toLocaleDateString('pt-BR', options);
  };

  const formatCurrency = (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    return null;
  };

  const transformDataByDate = (data) => {
    const pivotData = [];
    const dates = [...new Set(data.map(item => item.data))];
    const vendedores = [...new Set(data.map(item => item.nomeVendedor))];
    const colTotals = {};

    dates.forEach(date => {
      const row = { Data: new Date(date), DataVenda: formatDate(date), Total: 0, CodigoLoja: data.find(item => item.data === date).codigoLoja };
      vendedores.forEach(vendedor => {
        const venda = data.find(item => item.data === date && item.nomeVendedor === vendedor);
        const valor = venda ? venda.valorTotal : 0;
        row[vendedor] = formatCurrency(valor);
        row.Total += valor;
        colTotals[vendedor] = (colTotals[vendedor] || 0) + valor;
      });
      row.Total = formatCurrency(row.Total);
      pivotData.push(row);
    });

    pivotData.sort((a, b) => a.Data - b.Data); // Ordena as linhas pela data

    // Add totals row
    const totalsRow = { DataVenda: 'Total', Total: 0 };
    vendedores.forEach(vendedor => {
      totalsRow[vendedor] = formatCurrency(colTotals[vendedor]);
      totalsRow.Total += colTotals[vendedor];
    });
    totalsRow.Total = formatCurrency(totalsRow.Total);
    pivotData.push(totalsRow);

    return { pivotData, vendedores };
  };

  const transformDataByStore = (data) => {
    const pivotData = [];
    const stores = [...new Set(data.map(item => item.codigoLoja))];
    const vendedores = [...new Set(data.map(item => item.nomeVendedor))];
    const colTotals = {};
  
    vendedores.forEach(vendedor => {
      const row = { Vendedor: vendedor, Total: 0 };
      stores.forEach(store => {
        const vendas = data.filter(item => item.codigoLoja === store && item.nomeVendedor === vendedor);
        const valor = vendas.reduce((sum, venda) => sum + venda.valorTotal, 0);
        row[store] = formatCurrency(valor);
        row.Total += valor;
        colTotals[store] = (colTotals[store] || 0) + valor;
      });
      row.Total = formatCurrency(row.Total);
      pivotData.push(row);
    });
  
    // Add totals row
    const totalsRow = { Vendedor: 'Total', Total: 0 };
    stores.forEach(store => {
      totalsRow[store] = formatCurrency(colTotals[store]);
      totalsRow.Total += colTotals[store];
    });
    totalsRow.Total = formatCurrency(totalsRow.Total);
    pivotData.push(totalsRow);
  
    return { pivotData, stores };
  };

  const GetVendas = async (matrizId) => {
    try {
      setLoading(true);
      const params = {};
      if (matrizId) params.matrizId = matrizId;
      if (view === 'date' && SelectedLoja) params.codigoLoja = SelectedLoja.code; // Ignora o filtro de loja na visão "store"
      const startDate = dateRange && dateRange[0] ? dateRange[0] : new Date();
      const endDate = dateRange && dateRange[1] ? dateRange[1] : new Date();
      params.dataInicio = startDate.toISOString();
      params.dataFim = endDate.toISOString();
      
      const data = await Requicicao.Get({
        endpoint: "/Venda/ObterResumoVendasConsolidadaPorVendedor",
        params,
        config: auth.GetHeaders()
      });

      if (view === 'date') {
        const { pivotData, vendedores } = transformDataByDate(data.objetoResposta);
        setLista(pivotData);
        setColunas(['DataVenda', 'CodigoLoja', ...vendedores, 'Total']);
      } else {
        const { pivotData, stores } = transformDataByStore(data.objetoResposta);
        setLista(pivotData);
        setColunas(['Vendedor', ...stores, 'Total']);
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao obter as vendas."
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const GetCodigosLojasPorMatriz = async (matrizId) => {
    try {
      const params = { matrizId };
      const data = await Requicicao.Get({
        endpoint: "/Empresa/ObterCodigosLojasPorMatriz",
        params,
        config: auth.GetHeaders()
      });
      const lojas = data.objetoResposta.map(loja => ({ code: loja.codigoLoja, name: loja.codigoLoja }));
      setListaLojas(lojas);
      if (lojas.length > 0) {
        setSelectedLoja(lojas[0]);
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao obter os códigos das lojas."
      });
      console.error(error);
    }
  };

  const truncate = (str, length) => {
    if (str.length <= length) return str;
    return `${str.substring(0, length)}...`;
  };

  const GetAllEmpresas = async () => {
    try {
      const resposta = await Requicicao.Get({
        endpoint: "/Empresa/ObterMatrizesUsuarioAutenticado",
        config: auth.GetHeaders()
      });
      const empresas = resposta.objetoResposta.map((item) => ({
        code: item.id,
        name: `${truncate(item.nomeCompletoOuRazaoSocial, 30)} ${item.cpfOuCnpj}`
      }));
      setListaEmpresas(empresas);
      if (empresas.length > 0) {
        const firstEmpresa = empresas[0];
        setSelectedEmpresa(firstEmpresa);
        await GetCodigosLojasPorMatriz(firstEmpresa.code);
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao obter a lista de empresas."
      });
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      GetAllEmpresas();
    }
  }, [auth]);

  useEffect(() => {
    if (SelectedEmpresa) {
      GetCodigosLojasPorMatriz(SelectedEmpresa.code);
    }
  }, [SelectedEmpresa]);

  useEffect(() => {
    if (SelectedEmpresa && (dateRange && dateRange[0] && dateRange[1])) {
      GetVendas(SelectedEmpresa.code);
    }
  }, [SelectedEmpresa, dateRange, SelectedLoja, view]); // Adicione `view` como dependência

  const totalTemplate = (rowData, column) => {
    const isTotalRow = rowData.DataVenda === 'Total' || rowData.Vendedor === 'Total';
    const value = rowData[column.field];
    return (
      <span style={{ fontWeight: isTotalRow || column.field === 'Total' ? 'bold' : 'normal' }}>
        {value}
      </span>
    );
  };

  return (
    <div>
      <div className="card p-3">
        <div className="d-flex justify-content-center flex-row gap-3 align-items-center mb-3">
          <span className="text-muted">Visões:</span>
          <Button
            type="button"
            label="Vendedor por data"
            onClick={() => setView('date')}
            className={view === 'date' ? 'p-button-primary' : 'p-button-secondary'}
          />
          <Button
            type="button"
            label="Vendedor por loja"
            onClick={() => setView('store')}
            className={view === 'store' ? 'p-button-primary' : 'p-button-secondary'}
          />
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-row gap-3">
            <ExportToExcel data={Lista} fileName="Vendas" /> {/* Use o componente aqui */}
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Limpar filtro"
              outlined
              onClick={ClearFilter}
            />
          </div>
          <div className="d-flex gap-3 align-items-center">
            <Dropdown
              value={SelectedEmpresa}
              onChange={(e) => setSelectedEmpresa(e.value)}
              options={ListaEmpresas}
              optionLabel="name"
              placeholder="Filtro por empresa"
            />
            {view === 'date' && (
              <Dropdown
                value={SelectedLoja}
                onChange={(e) => setSelectedLoja(e.value)}
                options={ListaLojas}
                optionLabel="name"
                placeholder="Filtro por loja"
              />
            )}
            <Calendar
              value={dateRange}
              onChange={(e) => setDateRange(e.value)}
              selectionMode="range"
              readOnlyInput
              placeholder="Selecionar período"
              dateFormat="dd/mm/yy"
              locale="pt-BR" // Aplique a localidade
            />
            <Button
              type="button"
              icon="pi pi-search"
              label="Buscar"
              onClick={() => GetVendas(SelectedEmpresa ? SelectedEmpresa.code : null)}
            />
          </div>
        </div>
        <DataTable
          value={Lista}
          stripedRows
          paginator
          rows={32}
          tableStyle={{ minWidth: "20rem" }}
          emptyMessage="Nenhuma venda encontrada."
          globalFilterFields={Colunas}
          filters={filters}
        >
          {Colunas.map((col) => (
            <Column 
              key={col} 
              field={col} 
              header={col} 
              body={totalTemplate}
            />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default ListaVendasConsolidadasPorVendedor;
