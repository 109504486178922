import { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";
import Api from "utils/Api";

const ListarHealthCheck = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [Lista, setLista] = useState([]);
  const [filters, setFilters] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [codigoLojaFilter, setCodigoLojaFilter] = useState("");
  const [logDialogVisible, setLogDialogVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState("");

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      dataCriacao: { value: null, matchMode: FilterMatchMode.DATE_IS },
      codigoDaLoja: { value: "", matchMode: FilterMatchMode.STARTS_WITH }
    });
  };

  const ClearFilter = () => {
    InitFilters();
    setDateRange(null);
    setCodigoLojaFilter("");
  };

  const adjustDate = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(adjustedDate.getHours() - 3);
    return adjustedDate;
  };

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return adjustDate(date).toLocaleDateString('pt-BR', options);
  };

  const formatTime = (date) => {
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return adjustDate(date).toLocaleTimeString('pt-BR', options);
  };

  const GetHealthCheckData = async () => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/HealthCheck/ObterTodos",
        config: auth.GetHeaders()
      });

      const transformedData = data
        .map((item) => ({
          ...item,
          dataCriacao: new Date(item.dataCriacao)
        }))
        .sort((a, b) => b.dataCriacao - a.dataCriacao);

      setLista(transformedData);
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao obter os dados de health check."
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      GetHealthCheckData();
      InitFilters();
    }
  }, [auth]);

  const onDateChange = (e) => {
    const { value } = e;
    setDateRange(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCriacao: { value, matchMode: FilterMatchMode.CUSTOM }
    }));
  };

  const onCodigoLojaChange = (e) => {
    const { value } = e.target;
    setCodigoLojaFilter(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      codigoDaLoja: { value, matchMode: FilterMatchMode.STARTS_WITH }
    }));
  };

  const filterByDateRange = (value, filter) => {
    if (!filter || !filter.length || filter.length !== 2) {
      return true;
    }
    const dateValue = new Date(value);
    const startDate = new Date(filter[0]);
    const endDate = new Date(filter[1]);

    // Ajusta a hora do final do intervalo para garantir que o filtro pegue o dia todo
    endDate.setHours(23, 59, 59, 999);

    return dateValue >= startDate && dateValue <= endDate;
  };

  const logDeAlteracaoBodyTemplate = (rowData) => (
    <Button
      type="button"
      label="Ver Log"
      className="p-button-link"
      onClick={() => {
        setSelectedLog(rowData.logDeAlteracao);
        setLogDialogVisible(true);
      }}
    />
  );

  return (
    <div>
      <div className="card p-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-row gap-3">
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Limpar filtro"
              outlined
              onClick={ClearFilter}
            />
          </div>
          <div className="d-flex gap-3 align-items-center">
            <span>Filtro por Data:</span>
            <Calendar
              value={dateRange}
              onChange={onDateChange}
              selectionMode="range"
              readOnlyInput
              placeholder="Selecionar período"
              dateFormat="dd/mm/yy"
              locale="pt-BR"
            />
            <span>Filtro por Código Loja:</span>
            <InputText
              value={codigoLojaFilter}
              onChange={onCodigoLojaChange}
              placeholder="Código Loja"
            />
          </div>
        </div>
        <DataTable
          value={Lista}
          stripedRows
          paginator
          rows={10}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhum dado encontrado."
          globalFilterFields={["dataCriacao", "codigoDaLoja", "nomeCompletoOuRazaoSocial", "apelidoOuNomeFantasia", "versao", "versaoDestino", "logDeAlteracao"]}
          filters={filters}
        >
          <Column field="dataCriacao" header="Data Criação" body={(rowData) => formatDate(rowData.dataCriacao)} sortable filterField="dataCriacao" filter filterFunction={filterByDateRange} />
          <Column field="horaCriacao" header="Hora Criação" body={(rowData) => formatTime(rowData.dataCriacao)} sortable />
          <Column field="codigoDaLoja" header="Código Loja" sortable filter filterField="codigoDaLoja" />
          <Column field="nomeCompletoOuRazaoSocial" header="Filial" sortable />
          <Column field="apelidoOuNomeFantasia" header="Loja" sortable />
          <Column field="versao" header="Versão" sortable />
          <Column field="versaoDestino" header="Versão Destino" sortable />
          <Column field="logDeAlteracao" header="Log de Alteração" body={logDeAlteracaoBodyTemplate} />
        </DataTable>
      </div>
      <Dialog
        header="Log de Alteração"
        visible={logDialogVisible}
        style={{ width: '50vw' }}
        onHide={() => setLogDialogVisible(false)}
      >
        <pre>{selectedLog}</pre>
      </Dialog>
    </div>
  );
};

export default ListarHealthCheck;
