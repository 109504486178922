import FormEmpresa from "components/FormEmpresa";

import useQuery from "utils/useQuery";

const EditarLoja = () => {
  const query = useQuery();

  return (
    <div className="card">
      <FormEmpresa EmpresaId={query.get("id")} />
    </div>
  );
};

export default EditarLoja;
