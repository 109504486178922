import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Button } from 'primereact/button';

const ExportToExcel = ({ data, fileName }) => {
  const exportToExcel = () => {
    const filteredData = data.map(({ Data, ...rest }) => {
      // Substitui "R$ " por "" e converte para número apenas se o valor for uma string
      const cleanedData = Object.keys(rest).reduce((acc, key) => {
        const value = rest[key];
        if (typeof value === 'string' && value.includes('R$')) {
          acc[key] = parseFloat(value.replace('R$', '').replace(/\./g, '').replace(',', '.'));
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});
      return cleanedData;
    });

    const ws = XLSX.utils.json_to_sheet(filteredData);

    // Aplica formatação monetária às colunas correspondentes
    const colLetters = Object.keys(filteredData[0]).map((_, idx) => String.fromCharCode(65 + idx)); // Gera letras de colunas (A, B, C, etc.)
    colLetters.forEach((col) => {
      for (let row = 2; row <= filteredData.length + 1; row += 1) { // +1 para incluir cabeçalho
        const cellRef = `${col}${row}`;
        if (ws[cellRef] && typeof ws[cellRef].v === 'number') {
          ws[cellRef].t = 'n'; // Define o tipo como número
          ws[cellRef].z = '"R$ "* #,##0.00'; // Define o formato de número como moeda
        }
      }
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Relatório');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <Button onClick={exportToExcel} className="btn btn-primary">
      Exportar para Excel
    </Button>
  );
};

export default ExportToExcel;
