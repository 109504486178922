import { useState, useEffect } from "react";

import { format } from "date-fns";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TriStateCheckbox } from "primereact/tristatecheckbox";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

const FazerInventario = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [Lista, setLista] = useState([]);
  const [ListaIndex, setListaIndex] = useState(1);
  const [ListaUpdata, setListaUpdata] = useState([]);

  const [ListaEmpresas, setListaEmpresas] = useState([]);
  const [ListaProdutos, setListaProdutos] = useState([]);

  const [SelectedEstoque, setSelectedEstoque] = useState(null);
  const [CodigoProduto, setCodigoProduto] = useState("");
  const [Automatica, setAutomatica] = useState(false);

  const [MudarEmpresa, setMudarEmpresa] = useState(true);

  const GetAllProdutos = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: "/Produto",
        config: auth.GetHeaders()
      });

      setListaProdutos(
        resposta.objetoResposta.map((item) => ({
          produtoId: Number(item.codigo),
          produtoNome: item.descricao
        }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const GetAllEmpresas = async () => {
    try {
      const resposta = await Requicicao.Get({
        endpoint: "/Empresa/ObterFiliais",
        config: auth.GetHeaders()
      });
      setListaEmpresas(
        resposta.objetoResposta.map((item) => ({
          code: item.id,
          name: `${item.codigoLojaQueEstaInserida} - ${item.apelidoOuNomeFantasia} ${item.cpfOuCnpj}`
        }))
      );
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao obter a lista de empresas."
      });
      console.error(error);
    }
  };

  const StateDataPage = async () => {
    await GetAllEmpresas();
    await GetAllProdutos();
  };

  const OnGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      const thisFilters = { ...filters };

      thisFilters.global.value = value;

      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      produtoNome: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    });
    setGlobalFilterValue("");
  };

  const ClearFilter = () => {
    InitFilters();
  };

  const AplicarInventario = async () => {
    try {
      setLoading(true);
      await Requicicao.Patch({
        endpoint: "/EstoquePorEmpresa/Inventariar",
        data: { estoquesPorEmpresas: ListaUpdata },
        config: auth.GetHeaders()
      });
      notify({
        type: "success",
        message: "Inventário criado com sucesso."
      });
    } catch (error) {
      notify({
        type: "error",
        message:
          "Erro ao tentar executar esta ação. Por favor, entre em contato com o suporte."
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const AdicionarItem = () => {
    try {
      const produto = ListaProdutos.filter(
        (x) => x.produtoId === Number(CodigoProduto)
      );
      if (produto.length > 0) {
        setLista((lista) => [
          ...lista,
          {
            ...produto[0],
            ...{
              id: ListaIndex,
              data: format(new Date(), "dd/MM/yyyy HH:mm:ss")
            }
          }
        ]);
        setListaUpdata((lista) => {
          const itemExistente = lista.findIndex(
            (item) => item.produtoId === produto[0].produtoId
          );

          if (itemExistente !== -1) {
            const novaLista = [...lista];
            novaLista[itemExistente] = {
              ...novaLista[itemExistente],
              quantidade: novaLista[itemExistente].quantidade + 1
            };

            return novaLista;
          }

          return [
            ...lista,
            {
              ...produto[0],
              ...{ empresaId: SelectedEstoque.code, quantidade: 1 }
            }
          ];
        });
        setListaIndex((index) => index + 1);
        const salva = JSON.stringify({
          Lista,
          ListaUpdata,
          ListaIndex,
          SelectedEstoque
        });
        localStorage.setItem("atuSelveInventario", salva);

        setMudarEmpresa(false);
        setCodigoProduto("");
      } else {
        notify({
          type: "aviso",
          message: "Pruduto não encontrado na base."
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage();
    }
    InitFilters();
  }, [auth]);

  return (
    <>
      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="estoque" className="form-label">
            Estoque de
          </label>
          <Dropdown
            id="estoque"
            className="w-100"
            value={SelectedEstoque}
            onChange={(e) => {
              setSelectedEstoque(e.value);
            }}
            options={ListaEmpresas}
            optionLabel="name"
            placeholder="Selecione"
            disabled={!MudarEmpresa}
          />
        </div>
        <div className="col-6">
          <label>Inserção automática</label>
          <TriStateCheckbox
            className="w-100 mt-2"
            value={Automatica ? true : null}
            onChange={() => setAutomatica(!Automatica)}
          />
        </div>
        <div className="col-6 mt-3">
          <label htmlFor="produtoId" className="form-label">
            Código do produto
          </label>
          <InputText
            id="produtoId"
            className="w-100"
            value={CodigoProduto}
            onChange={(e) => setCodigoProduto(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                console.log("12");
                AdicionarItem();
              }
            }}
          />
        </div>
        <div className="col-3">
          <div className="h-100 d-flex flex-column justify-content-end">
            <Button
              type="button"
              label="Adicionar"
              icon="pi pi-plus"
              className="btn"
              onClick={AdicionarItem}
              disabled={SelectedEstoque === null}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="h-100 d-flex flex-column justify-content-end">
            <Button
              type="button"
              label="Atualizar"
              icon="pi pi-undo"
              className="btn btn-primary"
              onClick={AplicarInventario}
              disabled={Lista.length === 0}
            />
          </div>
        </div>
      </div>
      <div className="card p-3">
        <div className="d-flex justify-content-between mb-3">
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Limpar filtro"
            outlined
            onClick={ClearFilter}
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={OnGlobalFilterChange}
              placeholder="Pesquisa Global"
            />
          </span>
        </div>
        <DataTable
          value={Lista}
          stripedRows
          paginator
          rows={20}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhum produto."
          globalFilterFields={["produtoNome"]}
          filters={filters}
          sortField="id" // Defina o campo pelo qual deseja ordenar (neste caso, "data")
          sortOrder={-1} // Define a ordem de classificação (1 para ascendente, -1 para descendente)
        >
          <Column field="produtoId" header="Código do produto" />
          <Column field="produtoNome" header="Nome do produto" />
          <Column field="data" header="Data" />
        </DataTable>
        <div className="mt-3">
          <DataTable
            value={ListaUpdata}
            stripedRows
            paginator
            rows={20}
            tableStyle={{ minWidth: "50rem" }}
            emptyMessage="Nenhum produto."
            sortField="produtoId"
          >
            <Column field="produtoId" header="Código do produto" sortable />
            <Column field="produtoNome" header="Nome do produto" sortable />
            <Column field="quantidade" header="Quantidade" sortable />
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default FazerInventario;
