import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import TrataRetornoApi from "utils/TrataRetornoApi";

import EmailModule from "../FormEmpresa/modules/EmailModule";
import EnderecoModule from "../FormEmpresa/modules/EnderecoModule";
import PessoaModule from "../FormEmpresa/modules/PessoaModule";
import TelefoneModule from "../FormEmpresa/modules/TelefoneModule";

const FormOperador = ({ operadorId }) => {
  const tratarRetornoApi = new TrataRetornoApi();
  const { setLoading } = useLoading();
  const auth = useAuth();
  const requisicao = new Api();
  const exibirNotificacao = useNotification();
  const navigate = useNavigate();
  const [Pessoa, setPessoa] = useState({
    nomeCompletoOuRazaoSocial: "",
    apelidoOuNomeFantasia: "",
    cpfOuCnpj: ""
  });
  const [ListaEnderecos, setListaEnderecos] = useState([]);
  const [LitasTelefone, setLitasTelefone] = useState([]);
  const [ListaEmail, setListaEmail] = useState([]);
  const [dados, setDados] = useState({
    pessoa: {
      nomeCompletoOuRazaoSocial: "",
      apelidoOuNomeFantasia: "",
      cpfOuCnpj: "",
      inscricaoEstadual: "",
      inscricaoMunicipal: "",
      enderecos: [],
      telefones: [],
      emails: []
    }
  });

  const FitroElementoslista = (lista, tiop) => {
    const newListe = [];

    switch (tiop) {
      case "enderecos":
        lista.forEach((e) => {
          const {
            id,
            apelido,
            cep,
            enderecoDaPessoa,
            numero,
            complemento,
            estado,
            cidade,
            bairro
          } = e;
          newListe.push({
            id,
            apelido,
            cep,
            enderecoDaPessoa,
            numero,
            complemento,
            estado,
            cidade,
            bairro
          });
        });
        return newListe;
      case "telefones":
        lista.forEach((e) => {
          const { id, apelido, ddd, numero } = e;
          newListe.push({
            id,
            apelido,
            ddd,
            numero
          });
        });
        return newListe;
      case "emails":
        lista.forEach((e) => {
          const { id, apelido, emailDaPessoa } = e;
          newListe.push({
            id,
            apelido,
            emailDaPessoa
          });
        });
        return newListe;
      default:
        return newListe;
    }
  };

  const criar = async () => {
    try {
      const resposta = await requisicao.Post({
        endpoint: `/Operador`,
        data: dados,
        config: auth.GetHeaders()
      });

      if (resposta?.codigoEstadoHttp === 200) {
        exibirNotificacao({
          type: "sucesso",
          message: "Operador criado com sucesso!"
        });

        navigate("/app/operador");
      }
    } catch (error) {
      exibirNotificacao({
        type: "erro",
        message: tratarRetornoApi.obterMensagemErro(error)
      });
    } finally {
      setLoading(false);
    }
  };

  const atualizar = async () => {
    try {
      const resposta = await requisicao.Put({
        endpoint: `/Operador`,
        data: dados,
        config: auth.GetHeaders()
      });

      if (resposta?.codigoEstadoHttp === 200) {
        exibirNotificacao({
          type: "sucesso",
          message: "Operador atualizado com sucesso!"
        });
      }
    } catch (error) {
      exibirNotificacao({
        type: "erro",
        message: tratarRetornoApi.obterMensagemErro(error)
      });
    } finally {
      setLoading(false);
    }
  };

  const obterPorId = async () => {
    try {
      setLoading(true);
      const { objetoResposta } = await requisicao.Get({
        endpoint: "/Operador/ObterPorId",
        params: { id: operadorId },
        config: auth.GetHeaders()
      });

      const {
        id,
        nomeCompletoOuRazaoSocial,
        apelidoOuNomeFantasia,
        cpfOuCnpj,
        inscricaoEstadual,
        inscricaoMunicipal
      } = objetoResposta.pessoa;

      setPessoa({
        id,
        nomeCompletoOuRazaoSocial,
        apelidoOuNomeFantasia,
        cpfOuCnpj,
        inscricaoEstadual,
        inscricaoMunicipal
      });

      setDados((esta) => ({
        ...esta,
        ...{
          id: objetoResposta.id,
          ehMatriz: objetoResposta.ehMatriz,
          restricoes: objetoResposta.restricoes,
          pontoVendaAnfitriaoId: objetoResposta.pontoVendaAnfitriaoId,
          pessoa: {
            ...esta.pessoa,
            ...{
              id,
              nomeCompletoOuRazaoSocial,
              apelidoOuNomeFantasia,
              cpfOuCnpj,
              inscricaoEstadual,
              inscricaoMunicipal,
              enderecos: FitroElementoslista(
                objetoResposta.pessoa.enderecos,
                "enderecos"
              ),
              telefones: FitroElementoslista(
                objetoResposta.pessoa.telefones,
                "telefones"
              ),
              emails: FitroElementoslista(
                objetoResposta.pessoa.emails,
                "emails"
              )
            }
          }
        }
      }));

      const listaEnderecos = [];
      objetoResposta.pessoa.enderecos.forEach((element, i) => {
        listaEnderecos.push({ ...element, indice: i });
      });
      setListaEnderecos(listaEnderecos);
      const listaTelefone = [];
      objetoResposta.pessoa.telefones.forEach((element, i) => {
        listaTelefone.push({ ...element, indice: i });
      });
      setLitasTelefone(listaTelefone);
      const listaEmail = [];
      objetoResposta.pessoa.emails.forEach((element, i) => {
        listaEmail.push({ ...element, indice: i });
      });
      setListaEmail(listaEmail);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const tratarEnvio = async (event) => {
    event.preventDefault();

    setLoading(true);

    if (!operadorId) await criar();
    else await atualizar();
  };

  useEffect(() => {
    const metodoAssincrono = async () => {
      await obterPorId();
    };

    if (auth.isAuthenticated() && operadorId) metodoAssincrono();
  }, [auth]);

  useEffect(() => {
    setDados({ ...dados, pessoa: { ...dados.pessoa, ...Pessoa } });
  }, [Pessoa]);

  useEffect(() => {
    if (LitasTelefone.length > 0) {
      const newLista = [];
      LitasTelefone.forEach((e) => {
        const { id, apelido, ddd, numero } = e;
        newLista.push({
          id,
          apelido,
          ddd,
          numero
        });
      });

      setDados((este) => ({
        ...este,
        pessoa: { ...dados.pessoa, telefones: newLista }
      }));
    }
  }, [LitasTelefone]);

  useEffect(() => {
    if (ListaEnderecos.length > 0) {
      const newLista = [];
      ListaEnderecos.forEach((e) => {
        const {
          id,
          apelido,
          cep,
          enderecoDaPessoa,
          numero,
          complemento,
          estado,
          cidade,
          bairro
        } = e;
        newLista.push({
          id,
          apelido,
          cep,
          enderecoDaPessoa,
          numero,
          complemento,
          estado,
          cidade,
          bairro
        });
      });

      setDados((este) => ({
        ...este,
        pessoa: { ...dados.pessoa, enderecos: newLista }
      }));
    }
  }, [ListaEnderecos]);

  useEffect(() => {
    if (ListaEmail.length > 0) {
      const newLista = [];
      ListaEmail.forEach((e) => {
        const { id, apelido, emailDaPessoa } = e;
        newLista.push({ id, apelido, emailDaPessoa });
      });

      setDados((este) => ({
        ...este,
        pessoa: { ...dados.pessoa, emails: newLista }
      }));
    }
  }, [ListaEmail]);

  return (
    <form onSubmit={tratarEnvio} className="card-body">
      <div className="row">
        <PessoaModule Pessoa={Pessoa} setPessoa={setPessoa} />

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <EnderecoModule
          ListaEnderecos={ListaEnderecos}
          setListaEnderecos={setListaEnderecos}
        />

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <TelefoneModule
          LitasTelefone={LitasTelefone}
          setLitasTelefone={setLitasTelefone}
        />

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <EmailModule ListaEmail={ListaEmail} setListaEmail={setListaEmail} />

        <div className="col-md-12 mb-3">
          <div className="w-100 d-flex flex-row-reverse">
            <Button type="submit" label="Salvar" className="btn btn-primary" />
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormOperador;
